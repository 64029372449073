import React, { Fragment, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ProfileMenu from './ProfileMenu';

interface ILocation {
  pathname: string;
}

const Header = (): ReactElement => {
  const { pathname }: ILocation = useLocation();
  const navigate = useNavigate();

  const pageTitle = (): string => pathname.split('/')[1].replaceAll('-', ' ');

  const showBackBtn = (): boolean => {
    const updateShipmentPattern = /^\/update-shipment\/[a-fA-F0-9-]+$/;
    const updateCustomerPattern = /^\/update-customer\/[a-fA-F0-9-]+$/;
    const updateFlightPattern = /^\/update-flight\/[a-fA-F0-9-]+$/;
    const invoicePattern = /^\/invoice\/[a-fA-F0-9-]+$/;
    const manifestInvoicePattern = /^\/outbound-manifest-invoice\/[0-9]+$/;

    return ([
        '/add-outbound-awb',
        '/add-user',
        '/add-customer',
        '/add-outbound-manifest',
        '/add-outbound-customer-invoice',
        '/add-inbound-manifest',
        '/add-inbound-awb',
        '/add-inbound-customer-invoice'
      ].includes(pathname)) ||
      ([
        updateShipmentPattern.test(pathname),
        updateCustomerPattern.test(pathname),
        updateFlightPattern.test(pathname),
        invoicePattern.test(pathname),
        manifestInvoicePattern.test(pathname),
      ].some(Boolean));
  };

  const navigateBack = (): void => {
    navigate(-1);
  };

  return (
    <Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            {
              showBackBtn() &&
              <i
                className="ri-arrow-left-line align-middle cursor-pointer font-size-22 ms-3"
                onClick={() => navigateBack()}
              />
            }
            <p className="title">{pageTitle()}</p>
          </div>

          <div className="d-flex">
            <ProfileMenu/>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
