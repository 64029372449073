import { HandlerResponse, getRequestWithoutToken } from './api';
import { ObjToQueryString } from '../utils/Utils';

export const getLocationsAPI = async (): Promise<any> => {
  return getRequestWithoutToken(`${process.env.REACT_APP_API_ENDPOINT}/stations`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getWarehouseLocationsAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequestWithoutToken(`${process.env.REACT_APP_API_ENDPOINT}/location${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};
