import React, { forwardRef, Fragment, ReactElement, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAlertStore } from '../../store/store';

import {
  createCustomerAPI,
  getCustomerByIdAPI,
  updateCustomerAPI,
} from '../../api-services/customer.api';
import { validateGst, validatePan } from '../../utils/Utils';

interface IForm {
  customerName: string;
  blockNo: string;
  locality: string;
  town: string;
  pinCode: string;
  state: string;
  phone: string;
  email: string;
  gst: string;
  pan: string;
}

const formInit: IForm = {
  customerName: '',
  blockNo: '',
  locality: '',
  town: '',
  pinCode: '',
  state: '',
  phone: '',
  email: '',
  gst: '',
  pan: '',
};

const formErrorInit = {
  customerName: false,
  blockNo: false,
  locality: false,
  town: false,
  pinCode: false,
  state: false,
  phone: false,
};

interface IProps {
  isModal?: boolean,
  onCheckFormValidity?: (status: boolean) => void,
  onAdd?: () => void,
}

const AddCustomerScreen = forwardRef((props: IProps, ref): ReactElement => {
  const { isModal, onCheckFormValidity, onAdd } = props;

  const navigate = useNavigate();
  const { id } = useParams();

  const [form, setForm] = useState<IForm>(formInit);
  const [formError, setFormError] = useState<any>(formErrorInit);

  const updateAlertText = useAlertStore(state => state.updateAlertText);

  useEffect(() => {
    if (!!id) {
      getCustomerById(id);
    }
  }, [id]);

  useEffect(() => {
    if (isModal) {
      !!onCheckFormValidity && onCheckFormValidity(formValidity());
    }
  }, [form]);

  useImperativeHandle(ref, () => ({
    onCreate,
  }));

  const onUpdateForm = (e: any, type: string): void => {
    const updatedForm: any = { ...form };
    if (type === 'pan') {
      updatedForm[type] = e.toUpperCase();
    } else if (type === 'gst') {
      updatedForm[type] = e.toUpperCase();
    } else {
      updatedForm[type] = e;
    }
    setForm(updatedForm);
    setFormError({ ...formError, [type]: false });
  };

  const formValidity = (): boolean => {
    return (
      form.customerName !== '' &&
      form.blockNo !== '' &&
      form.locality !== '' &&
      form.town !== '' &&
      form.pinCode !== '' &&
      form.state !== '' &&
      form.phone.length === 10
    );
  };

  const onReset = (): void => {
    setForm(formInit);
    setFormError(formErrorInit);
  };

  const onCreate = (): void => {
    const payload = { ...form };

    createCustomerAPI(payload)
      .then((res: any) => {
        if (res) {
          updateAlertText('Customer created successfully!!!', 'success');

          if (!isModal) {
            navigate('/customer');
          } else {
            !!onAdd && onAdd();
          }
        } else {
          updateAlertText('Something went wrong!!!', 'danger');
        }
      });
  };

  const onUpdate = (): void => {
    const payload = { uuid: id, ...form, };

    updateCustomerAPI(payload)
      .then((res: any) => {
        if (res) {
          updateAlertText('Customer updated successfully!!!', 'success');
          navigate('/customer');
        } else {
          updateAlertText('Something went wrong!!!', 'danger');
        }
      });
  };

  const getCustomerById = (id: string) => {
    getCustomerByIdAPI(id)
      .then((res: any) => {
        if (res && res.payload) {
          setForm(() => ({
            customerName: res.payload.name,
            blockNo: res.payload.blockNo,
            locality: res.payload.locality,
            town: res.payload.town,
            pinCode: res.payload.pinCode,
            state: res.payload.state,
            phone: res.payload.phone,
            email: res.payload.email,
            gst: res.payload.gst,
            pan: res.payload.pan,
          }));
        }
      });
  };

  return (
    <Fragment>
      <div className="content">
        <div className="card mb-4">
          {!isModal && <p className="card-title mb-3">Customer Info</p>}

          <div className="row">
            <div className="col-md-3 mb-2">
              <label
                id="customer-customerName"
                className="form-label-text d-block mb-1"
              >
                Customer Name <span className="text-danger">*</span>
              </label>
              <input
                id="customer-customerName"
                type="text"
                placeholder="Enter customer name"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'customerName')}
                value={form.customerName}
              />
              {formError.customerName && (
                <p className="form-label-text text-danger">
                  Please enter customer name
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-blockNo"
                className="form-label-text d-block mb-1"
              >
                Flat/Room/Door/Block No{' '}
                <span className="text-danger">*</span>
              </label>
              <input
                id="customer-blockNo"
                type="text"
                placeholder="Enter Flat/Room/Door/Block No"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'blockNo')}
                value={form.blockNo}
              />
              {formError.blockNo && (
                <p className="form-label-text text-danger">
                  Please enter Flat/Room/Door/Block No
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-locality"
                className="form-label-text d-block mb-1"
              >
                Locality or Area Name <span className="text-danger">*</span>
              </label>
              <input
                id="customer-locality"
                type="text"
                placeholder="Enter Locality or Area Name"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'locality')}
                value={form.locality}
              />
              {formError.locality && (
                <p className="form-label-text text-danger">
                  Please enter Locality or Area Name
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-town"
                className="form-label-text d-block mb-1"
              >
                Town/City <span className="text-danger">*</span>
              </label>
              <input
                id="customer-town"
                type="text"
                placeholder="Enter Town/City"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'town')}
                value={form.town}
              />
              {formError.town && (
                <p className="form-label-text text-danger">
                  Please enter Town/City
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-pinCode"
                className="form-label-text d-block mb-1"
              >
                Pin Code <span className="text-danger">*</span>
              </label>
              <input
                id="customer-pinCode"
                type="text"
                placeholder="Enter Pin Code"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'pinCode')}
                value={form.pinCode}
              />
              {formError.pinCode && (
                <p className="form-label-text text-danger">
                  Please enter Pin Code
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-state"
                className="form-label-text d-block mb-1"
              >
                State <span className="text-danger">*</span>
              </label>
              <input
                id="customer-state"
                type="text"
                placeholder="Enter State"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'state')}
                value={form.state}
              />
              {formError.state && (
                <p className="form-label-text text-danger">
                  Please enter State
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-phone"
                className="form-label-text d-block mb-1"
              >
                Customer Phone <span className="text-danger">*</span>
              </label>
              <input
                id="customer-phone"
                type="number"
                placeholder="Enter Customer Phone"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'phone')}
                value={form.phone}
              />
              {formError.phone && (
                <p className="form-label-text text-danger">
                  Please enter customer phone
                </p>
              )}
              {!!form.phone && form.phone.length !== 10 && (
                <p className="form-label-text text-danger">
                  Please enter valid phone number
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-email"
                className="form-label-text d-block mb-1"
              >
                Email Id
              </label>
              <input
                id="customer-email"
                type="text"
                placeholder="Enter Email Id"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'email')}
                value={form.email}
              />
              {formError.email && (
                <p className="form-label-text text-danger">
                  Please enter Email Id
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-pan"
                className="form-label-text d-block mb-1"
              >
                PAN Number
              </label>
              <input
                id="customer-pan"
                type="text"
                placeholder="Enter Pan Number"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'pan')}
                value={form.pan}
              />
              {!!form.pan && !validatePan(form.pan) && (
                <p className="form-label-text text-danger">
                  Please enter valid PAN number
                </p>
              )}
            </div>

            <div className="col-md-3 mb-2">
              <label
                id="customer-gst"
                className="form-label-text d-block mb-1"
              >
                GST Number
              </label>
              <input
                id="customer-gst"
                type="text"
                placeholder="Enter GST"
                className="form-input-field w-100"
                onChange={(e) => onUpdateForm(e.target.value, 'gst')}
                value={form.gst}
              />
              {!!form.gst && !validateGst(form.gst) && (
                <p className="form-label-text text-danger">
                  Please enter valid GST number
                </p>
              )}
            </div>
          </div>

        </div>

        {
          !isModal &&
          <footer
            className="text-center text-lg-start fixed-bottom"
            style={{
              backgroundColor: '#ffffff',
              paddingBottom: '0.5rem',
              paddingRight: '1rem',
              zIndex: 1,
            }}
          >
            <div className="text-right mt-2">
              <button
                className="app-btn app-btn-primary-inverted me-3"
                onClick={() => !!id ? getCustomerById(id) : onReset()}
              >
                Reset
              </button>
              {
                !!id
                  ? <button
                    className="app-btn app-btn-primary"
                    onClick={() => onUpdate()}
                  >
                    Update
                  </button>
                  : <button
                    className="app-btn app-btn-primary"
                    onClick={() => onCreate()}
                    disabled={!formValidity()}
                  >
                    Create
                  </button>
              }
            </div>
          </footer>
        }
      </div>
    </Fragment>
  );
});

export default AddCustomerScreen;
