import { getRequest, HandlerResponse, postRequest, postRequestNoToken } from './api';

export const getUserProfileAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/users/profile`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getAllUserAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/users`)
    .then((resp: any) => HandlerResponse(resp));
};

export const createUserAPI = async (payload: any): Promise<any> => {
  return postRequestNoToken(`${process.env.REACT_APP_API_ENDPOINT}/users`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

