import React, { ReactElement } from 'react';
import { Spinner } from 'reactstrap';

import styles from './PageLoader.module.scss';

const SectionLoader = (): ReactElement | null => {
  return (
    <div className={styles['loading-container']}>
      <Spinner className={styles['loading-icon']}>Loading...</Spinner>
    </div>
  );
};

export default SectionLoader;
