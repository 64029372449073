import React, { ReactElement, useEffect } from 'react';

import Logo from '../../assets/images/logo/omega-logo.png';

import { useSidebarStore } from '../../store/store';

import SidebarContent from './SidebarContent';
import { ISidebarData } from './Layout';

interface IProps {
  sidebarData: ISidebarData[];
}

const Sidebar = (props: IProps): ReactElement => {
  const toggleSidebar = useSidebarStore(state => state.toggleSidebar);
  const leftSideBarType = useSidebarStore(state => state.leftSideBarType);

  const toggleHandler = (type: string): void => {
    toggleSidebar(type);

    if (type === 'default') {
      document.body.classList.remove('vertical-collapsed');
    } else {
      document.body.classList.add('vertical-collapsed');
    }
  };

  useEffect((): void => {
    document.body.classList.add('vertical-collapsed');
  }, []);

  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <div className="header-logo">
          <div
            onClick={() => toggleHandler(leftSideBarType === 'default' ? 'condensed' : 'default')}
            className="px-3 font-size-24"
          >
            <img src={Logo} alt="logo" height="40" className="cursor-pointer"/>
          </div>
          <div className="logo-title">
            <p>Omega</p>
          </div>
        </div>

        <SidebarContent
          sidebarData={props.sidebarData}
        />
      </div>
    </div>
  );
};

export default Sidebar;
