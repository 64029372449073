import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getOutboundCustomerInvoiceAPI } from '../../api-services/outboundCustomerInvoice';
import { dateTimeFormat } from '../../utils/Utils';

const OutboundCustomerInvoicePage = (): ReactElement => {
  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');


  useEffect(() => {
    getOutboundCustomerInvoice();
  }, []);

  const getOutboundCustomerInvoice = (): void => {
    getOutboundCustomerInvoiceAPI()
      .then((res: {payload: any[]}) => {
        if (res && searchKey) {
          const filteredData = res.payload.filter((data) =>
            data.awbNo.includes(searchKey)
          );
          setDataSource(filteredData);
        } else {
          setDataSource(res.payload);
        }
      });
  };

  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-md-4">
            <div className="input-group flex-nowrap">
              <input
                type="text"
                className="form-control"
                placeholder="Search Awb Number"
                aria-label="awbNumber"
                aria-describedby="addon-wrapping"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <span
                className="input-group-text"
                id="addon-wrapping"
                onClick={getOutboundCustomerInvoice}
              >
                <i className="fa fa-search cursor-pointer"/>
              </span>
            </div>
          </div>
          <div className="col-md-8 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => navigate('/add-outbound-customer-invoice')}
            >
              Add Outbound Customer Invoice
            </button>
          </div>

          {/* <div className="offset-md-10 col-md-2 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => navigate('/add-outbound-customer-invoice')}
            >
              Add Outbound Customer Invoice
            </button>
          </div> */}

          <div className="col-md-12">
            <div className="table-responsive mt-3">
              <table className="mb-0 w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>AWB No</th>
                  <th>Total no of Packages</th>
                  <th>Gross Weight</th>
                  <th>Agent Name</th>
                  <th>Net Amount</th>
                  <th>Flight No</th>
                  <th>Created Date</th>
                  <th>ACTION</th>
                </tr>
                </thead>
                <tbody>
                {dataSource.map((item, shipmentIdx: number) => (
                  <tr key={shipmentIdx}>
                    <td>{shipmentIdx + 1}</td>
                    <td>{item.awbNo}</td>
                    <td>{item.totalNoOfPackages}</td>
                    <td>{item.grossWeight}</td>
                    <td>{item.agentName}</td>
                    <td>{item.netAmount}</td>
                    <td>{item.flightDetails?.flightNo}</td>
                    <td>{dateTimeFormat(item.updatedAt)}</td>
                    <td className="text-center">
                      <i
                        className="fa fa-pencil-alt text-danger cursor-pointer me-3"
                        onClick={() => navigate(`/update-outbound-customer-invoice/${item.uuid}`)}
                      />
                      <i
                        className="fa fa-file-invoice text-primary cursor-pointer"
                        onClick={() => navigate(`/outbound-tax-invoice/${item.uuid}`)}
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutboundCustomerInvoicePage;
