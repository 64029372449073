import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { dateTimeFormat } from '../../utils/Utils';

import { getInboundAwbAPI } from '../../api-services/inboundAWB.api';

const InboundAwbPage = (): ReactElement => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');

  useEffect(() => {
    if (!id) {
      getInboundAwb();
    }
  }, []);

  const getInboundAwb = (): void => {
    getInboundAwbAPI().then((res: {payload: any[]}) => {
      if (res && searchKey) {
        const filteredData = res.payload.filter((data) =>
          data.airWaybillNumber.includes(searchKey)
        );
        setDataSource(filteredData);
      } else {
        setDataSource(res.payload);
      }
    });
  };

  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-md-4">
            <div className="input-group flex-nowrap">
              <input
                type="text"
                className="form-control"
                placeholder="Search Awb Number"
                aria-label="awbNumber"
                aria-describedby="addon-wrapping"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <span
                className="input-group-text"
                id="addon-wrapping"
                onClick={getInboundAwb}
              >
                <i className="fa fa-search cursor-pointer"/>
              </span>
            </div>
          </div>
          <div className="col-md-8 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => navigate('/add-inbound-awb')}
            >
              Add Inbound AWB
            </button>
          </div>

          <div className="col-md-12">
            <div className="table-responsive mt-3">
              <table className="mb-0 w-100">
                <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>AWB No</th>
                  <th>Origin</th>
                  <th>Destination</th>
                  <th>Total no of Pcs</th>
                  <th>Gross Weight</th>
                  <th>Chargeable Weight</th>
                  <th>Flight No & Date</th>
                  <th>SHC</th>
                  <th>Billing Code</th>
                  <th>Rate per KG</th>
                  <th>Amount to be collected</th>
                  <th>Created Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {dataSource.map((item, shipmentIdx: number) => (
                  <tr key={shipmentIdx}>
                    <td>{shipmentIdx + 1}</td>
                    <td>{item.airWaybillNumber}</td>
                    <td>{item.airportOfDeparture}</td>
                    <td>{item.airportOfDestination}</td>
                    <td>{item.totalNoOfPackages}</td>
                    <td>{item.grossWeight}</td>
                    <td>{item.chargeableWeight}</td>
                    <td>{item.flightList.map((flight: any, flightIdx: number) => (
                      <span key={flightIdx} style={{ display: 'block' }}>
                        {flight.flightNo} & {flight.date.split('T')[0]}
                      </span>
                    ))}</td>
                    <td>{item.flightList.map((flight: any) => flight.date).join(',')}</td>
                    <td>{item.specialHandlingCode}</td>
                    <td>{item.billingCode}</td>
                    <td> {item.ratePerKg}</td>
                    <td>{item.totalAmount}</td>
                    <td>{dateTimeFormat(item.updatedAt)}</td>
                    <td className="text-center">
                      <i
                        className="fa fa-pencil-alt text-danger cursor-pointer me-3"
                        onClick={() =>
                          navigate(`/update-inbound-awb/${item.uuid}`)
                        }
                      />
                      <i
                        className="fa fa-file-invoice text-primary cursor-pointer"
                        onClick={() =>
                          navigate(`/inbound-awb-invoice/${item.uuid}`)
                        }
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboundAwbPage;
