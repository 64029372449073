import { ObjToQueryString } from '../utils/Utils';

import { getRequest, HandlerResponse, postRequest, putRequest } from './api';

export const getAllOutboundFlightsAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/flights${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getAllInboundFlightsAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/flights/inbound-flight-list${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getAllFlightsByShipmentDateAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/flights/getAllFlightByDate${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getFlightsByIdAPI = async (id: any,queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/flights/flightById/${id}${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const updateFlightAPI = async (payload: any, id: any): Promise<any> => {
  return putRequest(`${process.env.REACT_APP_API_ENDPOINT}/flights/${id}`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const createFlightAPI = async (payload?: any): Promise<any> => {
  return postRequest(`${process.env.REACT_APP_API_ENDPOINT}/flights`, payload)
    .then((resp: any) => HandlerResponse(resp));
};
