import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IFlight } from '../../interfaces/flight.interface';

const FlightScreen = (): ReactElement => {
  const [selectDate, setSelectedDate] = useState();
  const [flightList, setFlightList] = useState<IFlight[]>([]);


  const dateChangeHandler = (e: any) => {
    /*let filteredShipment = shipments
      .filter((data) => data.shippingDate === e)
      .map((item) => item.selectedFlight);
    let filteredFlights = filteredShipment.flatMap((item) =>
      flights.filter((data) => data.flightNo === item)
    );
    setFlightList(filteredFlights);*/
  };
  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-2 mb-3">
            <label id="shipping-date" className="form-label-text d-block mb-1">
              Shipping Date
              <input
                id="shipping-date"
                type="date"
                className="form-input-field w-100"
                onChange={(e) => dateChangeHandler(e.target.value)}
                value={selectDate}
              />
            </label>
          </div>
        </div>
        <div className="card-title mb-3">Flight List</div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive mt-1 mb-3">
              <table className="mb-0 w-100">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>FLIGHT NO</th>
                  <th>SECTOR</th>
                  <th>STA</th>
                  <th>STD</th>
                  <th>AIRCRAFT TYPE</th>
                  {/* <th className="text-center">ACTION</th> */}
                </tr>
                </thead>
                <tbody>
                {flightList.map((data, dataIdx: number) => (
                  <tr key={dataIdx}>
                    <td>{data.id}</td>
                    <td>{data.flightNo}</td>
                    <td>{data.station}</td>
                    <td>{}</td>
                    <td>{data.departureTime}</td>
                    <td>{data.aircraftType}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightScreen;
