import React, { ReactElement } from 'react';
import { Spinner } from 'reactstrap';

import styles from './PageLoader.module.scss';

import { useLoaderStore } from '../../../store/store';

const PageLoader = (): ReactElement | null => {
  const showLoader = useLoaderStore(state => state.showLoader);

  if (!showLoader) {
    return null;
  }

  return (
    <div className={styles['loading-container']} style={{ zIndex: 9999 }}>
      <Spinner className={styles['loading-icon']}>Loading...</Spinner>
    </div>
  );
};

export default PageLoader;
