import { ObjToQueryString } from '../utils/Utils';

import { IInboundAwbPayload } from '../interfaces/inboundAwb.interface';
import { getRequest, HandlerResponse, postRequest, putRequest } from './api';

export const createInboundAirWayBillAPI = async (payload: IInboundAwbPayload): Promise<any> => {
  return postRequest(`${process.env.REACT_APP_API_ENDPOINT}/inbound-awb/create`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const getInboundAwbAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/inbound-awb/all-inbound-shipments`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getInboundAwbByIdAPI = async (id: string): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/inbound-awb/info/${id}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getInboundManifestByAwbNoAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/manifest/get-inbound-manifest-by-awbNo${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const updateInboundAirWayBillAPI = async (id: string, payload: IInboundAwbPayload): Promise<any> => {
  return putRequest(`${process.env.REACT_APP_API_ENDPOINT}/inbound-awb/update-inbound-shipments/${id}`, payload)
    .then((resp: any) => HandlerResponse(resp));
};
