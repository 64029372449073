import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useAlertStore } from '../../store/store';

import SectionLoader from './loader/SectionLoader';

import { flightInit, IFlight } from '../../interfaces/flight.interface';
import { getAllInboundFlightsAPI, getAllOutboundFlightsAPI } from '../../api-services/flight.api';
import { checkFlightWithSameDateAPI } from '../../api-services/shipment.api';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSelectFlight: (flightDetails: IFlight, shippingDate: string) => void;
  branch: string,
  type: 'outbound' | 'inbound';
}

const date = new Date();
const todayDate = [
  date.getFullYear(),
  (date.getMonth() + 1).toString().length === 1
    ? '0' + (date.getMonth() + 1)
    : date.getMonth() + 1,
  date.getDate().toString().length === 1
    ? '0' + date.getDate()
    : date.getDate(),
].join('-');

const FlightListModal = (props: IProps): ReactElement => {
  const { isOpen, setIsOpen, onSelectFlight, branch, type, } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shippingDate, setShippingDate] = useState<string>(todayDate);
  const [flightList, setFlightList] = useState<IFlight[]>([]);
  const [selectedFlight, setSelectedFlight] = useState<IFlight>(flightInit);

  const updateAlertText = useAlertStore(state => state.updateAlertText);

  useEffect(() => {
    getAllFlights();
  }, [shippingDate]);

  const getAllFlights = (): void => {
    setIsLoading(true);

    const params = { branch, shippingDate, };
    const apiCall = type === 'outbound'
      ? getAllOutboundFlightsAPI(params)
      : getAllInboundFlightsAPI(params);

    apiCall
      .then((res: {payload: IFlight[]}) => {
        setFlightList(!!res ? res.payload : []);
        setIsLoading(false);
      });
  };

  const checkFlightWithSameDate = (): void => {
    const params = { type, shippingDate, flightNo: selectedFlight.id };

    checkFlightWithSameDateAPI(params)
      .then((res: {message: string}) => {
        if (res.message === 'Successful') {
          updateAlertText('Shipment is already created with same Flight no and Shipment Date', 'warning');
        } else {
          setIsOpen(false);
          onSelectFlight(selectedFlight, shippingDate);
        }
      });
  };

  return (
    <Modal isOpen={isOpen} centered={true} style={{ maxWidth: '50%' }}>
      <ModalHeader>
        <p className="modal-title">Flight List</p>
      </ModalHeader>

      <ModalBody
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto',
        }}
      >
        <div className="row">
          <div className="col-4 mb-3">
            <label htmlFor="shipping-date" className="form-label-text me-2">
              Shipping Date
            </label>
            <input
              id="shipping-date"
              type="date"
              className="form-input-field"
              min={todayDate}
              onChange={(e) => setShippingDate(e.target.value)}
              value={shippingDate}
            />
          </div>

          {isLoading && <SectionLoader/>}

          <div className="col-md-12">
            <div className="table-responsive mb-3">
              <table className="mb-0 w-100">
                <thead>
                <tr>
                  <th>ID</th>
                  <th>FLIGHT NO</th>
                  <th>SECTOR</th>
                  <th>{type === 'outbound' ? 'STD' : 'STA'}</th>
                  <th>AIRCRAFT TYPE</th>
                  <th>ACTION</th>
                </tr>
                </thead>
                <tbody>
                {
                  flightList.map((data, dataIdx: number) => (
                    <tr key={dataIdx}>
                      <td>{data.id}</td>
                      <td>{data.flightNo}</td>
                      <td>{data.airportCode}</td>
                      <td>{type === 'outbound' ? data.departureTime : data.arrivalTime}</td>
                      <td>{data.aircraftType}</td>
                      <td className="text-center">
                        {
                          selectedFlight.flightNo === data.flightNo
                            ? <span className="text-primary">Booked</span>
                            : <span
                              className="text-primary cursor-pointer"
                              onClick={() => setSelectedFlight(data)}
                            >
                            Book
                          </span>
                        }
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <p
          className="form-label-text text-danger cursor-pointer me-4"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </p>
        <button
          className="app-btn app-btn-primary"
          disabled={!selectedFlight.flightNo}
          onClick={() => {
            if (props.type === 'inbound') {
              checkFlightWithSameDate()
            } else {
              setIsOpen(false);
              onSelectFlight(selectedFlight, shippingDate);
            }
          }}
        >
          Add Flight
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default FlightListModal;
