import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

import styles from './OutboundAwb.module.scss';

import { useAlertStore, useUserProfileStore } from '../../store/store';

import EstimationCostModal from './EstimationCostModal';
import FlightListModal from '../../components/common/FlightListModal';
import AddCustomerModal from './AddCustomerModal';

import {
  IForm,
  IFormErr,
  ISelectDropdown,
  IShipment,
  IShipmentPayload,
} from '../../interfaces/shipment.interface';
import { ICustomer } from '../../interfaces/customer.interface';
import { IFlight } from '../../interfaces/flight.interface';
import { IAirport } from '../../interfaces/airport.interface';
import { IBillingCode } from '../../interfaces/common.interface';
import { getCustomersAPI } from '../../api-services/customer.api';
import {
  createShipmentAPI,
  getHandlingCodesAPI,
  getShipmentByIdAPI, updateShipmentAPI,
} from '../../api-services/shipment.api';
import { getAirportListAPI } from '../../api-services/airport.api';

// const date = new Date();

const EditOutboundAwbPage = (): ReactElement => {
  const formInitial: IForm = {
    branch: [],
    /*shippingDate: [
      date.getFullYear(),
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1,
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate(),
    ].join('-'),*/
    selectedFlightList: [],
    shipperName: [],
    shipperPhone: '',
    shipperAddress: '',
    consigneeName: [],
    consigneePhone: '',
    consigneeAddress: '',
    agentCode: '',
    airWaybillNumber: '',
    airportOfDeparture: '',
    airportOfDestination: [],
    totalNoOfPackages: '',
    description: '',
    billingCode: [],
    shc: '',
    handlingInformation: '',
    grossWeight: '',
    packageList: [
      {
        uuid: '',
        length: '',
        width: '',
        height: '',
        quantity: '',
      },
    ],
    chargeableWeight: 0,
    ratePerKg: 0,
    amountToBeCollected: 0,
  };
  const formErrorInit: IFormErr = {
    branch: false,
    shippingDate: false,
    shipperName: false,
    shipperPhone: false,
    shipperAddress: false,
    consigneeName: false,
    consigneePhone: false,
    consigneeAddress: false,
    agentCode: false,
    airWaybillNumber: false,
    airportOfDeparture: false,
    airportOfDestination: false,
    totalNoOfPackages: false,
    description: false,
    billingCode: false,
    shc: false,
    handlingInformation: false,
    grossWeight: false,
    packageList: [
      {
        length: false,
        width: false,
        height: false,
        quantity: false,
      },
    ],
    chargeableWeight: false,
    ratePerKg: false,
    amountToBeCollected: false,
    orderId: false,
  };

  const navigate = useNavigate();
  const { id } = useParams();

  const [form, setForm] = useState<IForm>(formInitial);
  const [formError, setFormError] = useState<any>(formErrorInit);
  const [airports, setAirports] = useState<ISelectDropdown[]>([]);
  const [userList, setUserList] = useState<ICustomer[]>([]);
  const [customers, setCustomers] = useState<ISelectDropdown[]>([]);
  const [billingCodeList, setBillingCodeList] = useState<IBillingCode[]>([]);
  const [billingCodes, setBillingCodes] = useState<ISelectDropdown[]>([]);

  const [isEstimationCostModalOpened, setIsEstimationCostModalOpened] = useState<boolean>(false);
  const [isFlightListModalOpened, setIsFlightListModalOpened] = useState<boolean>(false);
  const [isAddCustomerModalOpened, setIsAddCustomerModalOpened] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>(id || '');

  const userProfile = useUserProfileStore((state) => state.userProfile);
  const updateAlertText = useAlertStore(state => state.updateAlertText);

  useEffect(() => {
    getAirports();
    getCustomers();
    getHandlingCodes();
  }, []);

  useEffect(() => {
    if (!!uuid && airports.length > 0 && customers.length > 0 && billingCodes.length > 0) {
      getShipmentById();
    }
  }, [
    airports.length > 0 && customers.length > 0 && billingCodes.length > 0
  ]);

  useEffect(() => {
    setForm({
      ...form,
      branch: [{ label: userProfile.branch, value: userProfile.branch }],
    });
  }, []);

  const getShipmentById = (): void => {
    getShipmentByIdAPI(uuid || '')
      .then((res: {payload: IShipment}) => {
        formInit(res.payload);
        if (res.payload.packageList.length > 1) {
          const errItems = [...formError.packageList];
          errItems.push(formErrorInit.packageList[0]);

          const additionalPushCount = res.payload.packageList.length - 2;

          for (let i = 0; i < additionalPushCount; i++) {
            errItems.push(formErrorInit.packageList[0]);
          }

          setFormError({ ...formError, packageList: errItems });
        }
      });
  };

  const getAirports = (): void => {
    getAirportListAPI()
      .then((res: {payload: IAirport[]}) => {
        setAirports(res.payload.map((airport) => ({ label: airport.airportCode, value: airport.airportCode })));
      });
  };

  const getCustomers = (): void => {
    getCustomersAPI()
      .then((res: {payload: ICustomer[]}) => {
        setUserList(res.payload);
        setCustomers(res.payload.map((customer) => ({ label: customer.name, value: customer.uuid })));
      });
  };

  const getHandlingCodes = (): void => {
    const params = { type: 'OUTBOUND' };

    getHandlingCodesAPI(params)
      .then((res: {payload: IBillingCode[]}) => {
        setBillingCodeList(res.payload);
        setBillingCodes(res.payload.map((billingCode) => ({ label: billingCode.label, value: billingCode.value })));
      });
  };

  const formInit = (data: IShipment): void => {
    const item = { ...form };
    item.branch = [{ label: data.branch, value: data.branch, }];
    item.selectedFlightList = data.flightList;
    item.shipperName = [{
      value: data.shipperDetails.uuid,
      label: data.shipperDetails.name,
    }];
    item.consigneeName = [{
      value: data.consigneeDetails.uuid,
      label: data.consigneeDetails.name,
    }];
    item.shipperPhone = data.shipperDetails.phone;
    item.shipperAddress = data.shipperDetails.blockNo + ' ' + data.shipperDetails.locality + ' ' +
      data.shipperDetails.town + ' ' + data.shipperDetails.state + ' ' + data.shipperDetails.pinCode;
    item.consigneePhone = data.consigneeDetails.phone;
    item.consigneeAddress = data.consigneeDetails.blockNo + ' ' + data.consigneeDetails.locality + ' ' +
      data.consigneeDetails.town + ' ' + data.consigneeDetails.state + ' ' + data.consigneeDetails.pinCode;
    item.agentCode = data.agentCode;
    item.airWaybillNumber = data.airWaybillNumber.split('-')[1];
    item.airportOfDestination = [{
      value: data.airportOfDestination,
      label: data.airportOfDestination,
    }];
    item.totalNoOfPackages = data.totalNoOfPackages;
    item.description = data.description;
    item.billingCode = [{
      value: data.billingCode,
      label: billingCodes.find((item) => item.value === data.billingCode)?.label || '',
    }];
    item.shc = data.shc;
    item.handlingInformation = data.handlingInformation;
    item.grossWeight = data.grossWeight;
    item.packageList = data.packageList;
    item.chargeableWeight = data.chargeableWeight;
    item.ratePerKg = data.ratePerKg;
    item.amountToBeCollected = data.amountToBeCollected;

    setForm(item);
  };

  const onUpdateForm = (value: any, type: string): void => {
    const updatedForm: any = { ...form };
    const updatedErrorForm: any = { ...formError };

    if (type !== 'selectedFlightList') {
      updatedForm[type] = value;
    }

    if (type === 'shipperName') {
      const shipper = userList.find(user => user.uuid === value[0].value);
      updatedForm['shipperPhone'] = shipper?.phone;
      updatedForm['shipperAddress'] = shipper?.blockNo + ' ' + shipper?.locality + ' ' + shipper?.town + ' ' +
        shipper?.state + ' ' + shipper?.pinCode;
    }

    if (type === 'consigneeName') {
      const consignee = userList.find(user => user.uuid === value[0].value);
      updatedForm['consigneePhone'] = consignee?.phone;
      updatedForm['consigneeAddress'] = consignee?.blockNo + ' ' + consignee?.locality + ' ' + consignee?.town + ' ' +
        consignee?.state + ' ' + consignee?.pinCode;
    }

    if (type === 'airWaybillNumber' && value.length !== 8) {
      updatedErrorForm['airWaybillNumber'] = true;
    } else {
      updatedErrorForm['airWaybillNumber'] = false;
    }

    if (type === 'selectedFlightList') {
      updatedForm.selectedFlightList.push({ ...value.flightDetails, shippingDate: value.shippingDate });
      if (!uuid) {
        updatedForm['airportOfDestination'] =
          [{
            label: updatedForm.selectedFlightList[0].airportCode,
            value: updatedForm.selectedFlightList[0].airportCode,
          }];
      }
    }

    if (type === 'billingCode') {
      updatedForm['ratePerKg'] = billingCodeList.find(billingCode => billingCode.value === value[0].value)?.rate;
      updatedForm['amountToBeCollected'] =
        parseFloat(billingCodeList.find(billingCode => billingCode.value === value[0].value)?.minimunPerAwb || '0');
    }

    setForm(updatedForm);
    setFormError(updatedErrorForm);
  };

  const totalEnteredQuantity = (): number => {
    return form.packageList.reduce((acc, value) => {
      acc += parseInt(value.quantity);
      return acc;
    }, 0);
  };

  const addPackage = (): void => {
    const items = [...form.packageList];
    items.push(formInitial.packageList[0]);
    setForm({ ...form, packageList: items });

    const errItems = [...formError.packageList];
    errItems.push(formErrorInit.packageList[0]);
    setFormError({ ...formError, packageList: errItems });
  };

  const removePackage = (packageIdx: number): void => {
    const items = [...form.packageList];
    items.splice(packageIdx, 1);
    setForm({ ...form, packageList: items });

    const errItems = [...formError.packageList];
    errItems.splice(packageIdx, 1);
    setFormError({ ...formError, packageList: errItems });
  };

  const removeFlight = (flightIdx: number): void => {
    const items = [...form.selectedFlightList];
    items.splice(flightIdx, 1);
    setForm({ ...form, selectedFlightList: items });
  }

  const getChargeableWeight = (): number => {
    const total = form.packageList.reduce((pInit, p) => {
      pInit += ((parseFloat(p.length) * parseFloat(p.width) * parseFloat(p.height)) / 6000) * parseFloat(p.quantity);
      return pInit;
    }, 0);

    return parseFloat(form.grossWeight) > total
      ? parseFloat(form.grossWeight)
      : parseFloat(total.toFixed(2));
  };

  const getTotalCost = (): number => {
    const totalWeight = getChargeableWeight() > parseFloat(form.grossWeight)
      ? getChargeableWeight()
      : parseFloat(form.grossWeight);
    const value = totalWeight * form.ratePerKg;
    const totalAmount = value > form.amountToBeCollected ? value : form.amountToBeCollected;
    return Math.round(parseFloat(totalAmount.toFixed(2)));
  };

  const formValidity = (): boolean => {
    return (
      form.branch.length > 0 &&
      // form.shippingDate !== '' &&
      form.selectedFlightList.reduce((acc, value) => acc && (value.bookedPcs > 0 && value.bookedWt > 0), true) &&
      form.shipperName.length > 0 &&
      form.consigneeName.length > 0 &&
      form.agentCode !== '' &&
      form.airWaybillNumber !== '' &&
      form.airWaybillNumber.length === 8 &&
      form.totalNoOfPackages !== '' &&
      form.billingCode.length > 0 &&
      form.shc !== '' &&
      form.grossWeight !== '' &&
      form.packageList.reduce(
        (acc, value) =>
          acc &&
          value.length !== '' &&
          value.width !== '' &&
          value.height !== '' &&
          value.quantity !== '',
        true
      )
    );
  };

  const searchFlightHandler = (): void => {
    setIsFlightListModalOpened(true);
  };

  /*const resetFlightHandler = (): void => {
    setForm((prevData) => ({
      ...prevData,
      fromDate: '',
      toDate: '',
      selectedFlight: flightInit,
    }));
  };*/

  const onReset = (): void => {
    setForm({ ...formInitial, branch: [{ label: userProfile.branch, value: userProfile.branch }] });
    setFormError(formErrorInit);
    setUuid('');
  };

  const openEstimationModal = (): void => {
    setIsEstimationCostModalOpened(true);
  };

  const onSave = (): void => {
    const payload: IShipmentPayload = {
      branch: form.branch[0].value,
      selectedFlightList: form.selectedFlightList.map(flight => ({
        uuid: flight.uuid,
        flightNo: flight.id,
        bookedPcs: flight.bookedPcs,
        bookedWt: flight.bookedWt,
        shippingDate: flight.shippingDate,
      })),
      shipperUuid: form.shipperName[0].value,
      consigneeUuid: form.consigneeName[0].value,
      agentCode: form.agentCode,
      // airWaybillNumber: `${form.selectedFlightList[0].awsCode}-${form.airWaybillNumber}`,
      airWaybillNumber: `${!!uuid ? form.selectedFlightList[0].flightDetails?.awsCode : form.selectedFlightList[0].awsCode}-${form.airWaybillNumber}`,
      airportOfDeparture: form.branch[0].value,
      airportOfDestination: form.airportOfDestination[0].value,
      totalNoOfPackages: parseInt(form.totalNoOfPackages),
      description: form.description,
      billingCode: form.billingCode[0].value,
      shc: form.shc,
      handlingInformation: form.handlingInformation,
      grossWeight: parseFloat(form.grossWeight),
      packageList: form.packageList.map((item) => ({
        uuid: item.uuid,
        length: parseFloat(item.length),
        width: parseFloat(item.width),
        height: parseFloat(item.height),
        quantity: parseFloat(item.quantity),
      })),
      chargeableWeight: getChargeableWeight(),
      ratePerKg: form.ratePerKg,
      amountToBeCollected: getTotalCost(),
    };

    const cleanUUIDs = (items: any) => items.map((item: any) => {
      if (!item.uuid) delete item.uuid;
      return item;
    });

    payload.selectedFlightList = cleanUUIDs(payload.selectedFlightList);
    payload.packageList = cleanUUIDs(payload.packageList);

    debugger;

    !!uuid ? updateShipmentAPI(uuid, payload) : createShipmentAPI(payload)
      .then((res: {message: string, payload: {shipmentUuid: string}}) => {
        if (!!res) {
          setUuid(res.payload.shipmentUuid);
          updateAlertText(res.message, 'success');
        } else {
          updateAlertText('This airwaybill number is already exist', 'danger');
        }
      });
  };

  return (
    <div className="content">
      <div className="card px-5 py-3">
        <div className="card-title mb-3">Shipment Info</div>

        <div className="row">
          <div className="col-2 mb-3">
            <label htmlFor="branch" className="form-label-text">
              Airport of Departure <span className="text-danger">*</span>
            </label>
            <Select
              id="branch"
              options={form.branch}
              isSearchable
              isDisabled
              placeholder="Select Branch"
              onChange={(e) => onUpdateForm([e], 'branch')}
              value={form.branch}
            />
            {formError.branch && (
              <p className="form-label-text text-danger">
                Please select airport of departure
              </p>
            )}
          </div>

          {/*<div className="col-2 mb-3">
            <label htmlFor="shipping-date" className="form-label-text d-block mb-1">
              Shipping Date
            </label>
            <input
              id="shipping-date"
              type="date"
              className="form-input-field w-100"
              disabled={!!uuid}
              onChange={(e) => onUpdateForm(e.target.value, 'shippingDate')}
              value={form.shippingDate}
            />
          </div>*/}

          {(!uuid && form.selectedFlightList.length === 0) && (
            <div className="col-8 mt-4">
              {/*<button
                className="app-btn app-btn-primary-inverted me-3"
                onClick={() => resetFlightHandler()}
              >
                Reset
              </button>*/}
              <button
                className="app-btn app-btn-primary"
                onClick={() => searchFlightHandler()}
              >
                Search Flight
              </button>
            </div>
          )}
        </div>

        <hr/>

        {
          form.selectedFlightList.length > 0 &&
          <Fragment>
            <div className="card-title mb-3">Flight Info</div>
            {
              form.selectedFlightList.map((flight, flightIdx: number) => (
                <div key={flightIdx} className="row mb-3">
                  <div className="col-7">

                    <div className="row">
                      <div className="col-3">
                        <label className="form-label-text d-block mb-1">Flight No</label>
                        <p>{!!flight.uuid ? flight.flightDetails?.flightNo : flight.flightNo}</p>
                      </div>
                      <div className="col-3">
                        <label className="form-label-text d-block mb-1">Destination</label>
                        <p>{!!flight.uuid ? flight.flightDetails?.airportCode : flight.airportCode}</p>
                      </div>
                      <div className="col-3">
                        <label className="form-label-text d-block mb-1">STD</label>
                        <p>{!!flight.uuid ? flight.flightDetails?.departureTime : flight.departureTime}</p>
                      </div>
                      <div className="col-3">
                        <label className="form-label-text d-block mb-1">Aircraft Type</label>
                        <p>{!!flight.uuid ? flight.flightDetails?.aircraftType : flight.aircraftType}</p>
                      </div>
                    </div>

                  </div>

                  <div className="col-5">

                    <div className="row">
                      <div className="col-5">
                        <label
                          htmlFor="booked-pcs"
                          className="form-label-text d-block mb-1"
                        >
                          Booked Pcs
                        </label>
                        <input
                          id="booked-pcs"
                          type="number"
                          placeholder="Enter booked pcs"
                          className="form-input-field w-100"
                          onChange={(e) => {
                            form.selectedFlightList[flightIdx].bookedPcs = !!e.target.value ? parseInt(e.target.value) : 0;
                            setForm({ ...form, selectedFlightList: form.selectedFlightList });
                          }}
                          value={flight.bookedPcs}
                        />
                      </div>
                      <div className="col-5">
                        <label
                          htmlFor="booked-wt"
                          className="form-label-text d-block mb-1"
                        >
                          Booked WT
                        </label>
                        <input
                          id="booked-wt"
                          type="number"
                          placeholder="Enter booked wt"
                          className="form-input-field w-100"
                          onChange={(e) => {
                            form.selectedFlightList[flightIdx].bookedWt = !!e.target.value ? parseFloat(e.target.value) : 0;
                            setForm({ ...form, selectedFlightList: form.selectedFlightList });
                          }}
                          value={flight.bookedWt}
                        />
                      </div>
                      {
                        !uuid &&
                        <div className="col-2 content-center">
                          <i
                            className="fa fa-trash text-danger cursor-pointer"
                            onClick={() => removeFlight(flightIdx)}
                          />
                        </div>
                      }
                    </div>

                  </div>

                </div>
              ))
            }

            <div className="row mt-3">
              <div className="offset-10 col-2 text-right">
                <button
                  className="app-btn app-btn-primary"
                  onClick={() => searchFlightHandler()}
                >
                  Create part booking
                </button>
              </div>
            </div>

            <hr/>

            <div className="card-title mb-3">Customer Info</div>

            <div className="row">
              <div className="col-3 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label
                    htmlFor="shipper-name"
                    className="form-label-text d-block mb-1"
                  >
                    Shipper Name <span className="text-danger">*</span>
                  </label>
                  <button
                    className="no-border no-background text-primary"
                    onClick={() => setIsAddCustomerModalOpened(true)}
                  >
                    +Add Shipper
                  </button>
                </div>
                <Select
                  id="shipper-name"
                  options={customers}
                  isSearchable
                  placeholder="Select Shipper Name"
                  onChange={(e) => onUpdateForm([e], 'shipperName')}
                  value={form.shipperName}
                />
                {formError.shipperName && (
                  <p className="form-label-text text-danger">
                    Please enter shipper name
                  </p>
                )}
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="shipper-phone"
                  className="form-label-text d-block mb-1"
                >
                  Shipper Phone
                </label>
                <input
                  id="shipper-phone"
                  type="number"
                  placeholder="Enter shipper phone"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.shipperPhone}
                />
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="shipper-address"
                  className="form-label-text d-block mb-1"
                >
                  Shipper Address
                </label>
                <input
                  id="shipper-address"
                  type="text"
                  placeholder="Enter shipper address"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.shipperAddress}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-3 mb-3">
                <label
                  htmlFor="consignee-name"
                  className="form-label-text d-block mb-1"
                >
                  Consignee Name <span className="text-danger">*</span>
                </label>
                <Select
                  id="consignee-name"
                  options={customers}
                  isSearchable
                  placeholder="Select Consignee Name"
                  onChange={(e) => onUpdateForm([e], 'consigneeName')}
                  value={form.consigneeName}
                />
                {formError.consigneeName && (
                  <p className="form-label-text text-danger">
                    Please enter consignee name
                  </p>
                )}
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="consignee-phone"
                  className="form-label-text d-block mb-1"
                >
                  Consignee Phone
                </label>
                <input
                  id="consignee-phone"
                  type="number"
                  placeholder="Enter consignee phone"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.consigneePhone}
                />
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="consignee-address"
                  className="form-label-text d-block mb-1"
                >
                  Consignee Address
                </label>
                <input
                  id="consignee-address"
                  type="text"
                  placeholder="Enter consignee address"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.consigneeAddress}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-3 mb-3">
                <label
                  htmlFor="agent-code"
                  className="form-label-text d-block mb-1"
                >
                  Agent Code <span className="text-danger">*</span>
                </label>
                <input
                  id="agent-code"
                  type="text"
                  placeholder="Enter agent code"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'agentCode')}
                  value={form.agentCode}
                />
              </div>
            </div>

            <hr/>

            <div className="card-title mb-3">Package Info</div>

            <div className="row">
              <div className="col-3 mb-3 position-relative">
                <label
                  htmlFor="air-waybill-number"
                  className="form-label-text d-block mb-1"
                >
                  Air Waybill Number <span className="text-danger">*</span>
                </label>
                <span className={styles['prefix-label']}>
                  {!!uuid ? form.selectedFlightList[0].flightDetails?.awsCode : form.selectedFlightList[0].awsCode} - </span>
                <input
                  id="air-waybill-number"
                  type="number"
                  placeholder="Enter air waybill number"
                  className="form-input-field w-100"
                  style={{ paddingLeft: '53px' }}
                  disabled={!!uuid}
                  onChange={(e) => onUpdateForm(e.target.value, 'airWaybillNumber')}
                  value={form.airWaybillNumber}
                />
                {formError.airWaybillNumber && form.airWaybillNumber.length !== 8 && (
                  <p className="form-label-text text-danger">
                    Air waybill number should be 8 digits
                  </p>
                )}
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="airport-of-departure"
                  className="form-label-text d-block mb-1"
                >
                  Airport of Departure
                </label>
                <input
                  id="airport-of-departure"
                  type="text"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.branch[0].value}
                />
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="airport-of-destination"
                  className="form-label-text d-block mb-1"
                >
                  Airport of Destination
                </label>
                <Select
                  id="airport-of-destination"
                  options={airports}
                  isSearchable
                  placeholder="Select airport of destination"
                  onChange={(e) => onUpdateForm([e], 'airportOfDestination')}
                  value={form.airportOfDestination}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-3 mb-3">
                <label
                  htmlFor="total-no-of-packages"
                  className="form-label-text d-block mb-1"
                >
                  Total No Of Packages <span className="text-danger">*</span>
                </label>
                <input
                  id="total-no-of-packages"
                  type="number"
                  placeholder="Enter total no of packages"
                  className="form-input-field w-100"
                  onChange={(e) => {
                    const value = e.target.value;
                    onUpdateForm(!!value ? parseInt(value) : 0, 'totalNoOfPackages');
                  }}
                  value={form.totalNoOfPackages}
                />
                {formError.totalNoOfPackages && (
                  <p className="form-label-text text-danger">
                    Please enter total no of packages
                  </p>
                )}
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="description"
                  className="form-label-text d-block mb-1"
                >
                  Description
                </label>
                <input
                  id="description"
                  type="text"
                  placeholder="Enter description"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'description')}
                  value={form.description}
                />
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="billing-code" className="form-label-text">
                  Billing Code <span className="text-danger">*</span>
                </label>
                <Select
                  id="billing-code"
                  options={billingCodes}
                  isSearchable
                  placeholder="Select billing code"
                  onChange={(e) => onUpdateForm([e], 'billingCode')}
                  value={form.billingCode}
                />
                {formError.billingCode && (
                  <p className="form-label-text text-danger">
                    Please select billing code
                  </p>
                )}
              </div>

              <div className="col-3 mb-3">
                <label htmlFor="shc" className="form-label-text">
                  SHC <span className="text-danger">*</span>
                </label>
                <input
                  id="shc"
                  type="text"
                  placeholder="Enter SHC"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'shc')}
                  value={form.shc}
                />
                {formError.shc && (
                  <p className="form-label-text text-danger">
                    Please enter SHC
                  </p>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-6 mb-3">
                <label
                  htmlFor="handling-information"
                  className="form-label-text d-block mb-1"
                >
                  Handling Information
                </label>
                <input
                  id="handling-information"
                  type="text"
                  placeholder="Enter handling information"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'handlingInformation')}
                  value={form.handlingInformation}
                />
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="gross-weight"
                  className="form-label-text d-block mb-1"
                >
                  Gross Weight <span className="text-danger">*</span>
                </label>
                <input
                  id="gross-weight"
                  type="number"
                  placeholder="Enter gross weight"
                  className="form-input-field w-100"
                  onChange={(e) => {
                    const value = e.target.value;
                    onUpdateForm(!!value ? e.target.value : 0, 'grossWeight');
                  }}
                  value={form.grossWeight}
                />
                {formError.totalNoOfPackages && (
                  <p className="form-label-text text-danger">
                    Please enter total no of packages
                  </p>
                )}
              </div>
            </div>

            {form.packageList.map((packageInfo, packageIdx: number) => (
              <div
                key={packageIdx}
                className={`row mb-3 ${styles['package-list-container']}`}
              >
                <div className="col-11">
                  <div className="row">
                    <div className="col-3 mb-3">
                      <label
                        htmlFor={`length-${packageIdx}`}
                        className="form-label-text"
                      >
                        Length <span className="text-danger">*</span>
                      </label>
                      <input
                        id={`length-${packageIdx}`}
                        type="number"
                        placeholder="Enter length"
                        className="form-input-field w-100"
                        onChange={(e) => {
                          form.packageList[packageIdx].length = e.target.value
                          setForm({ ...form, packageList: form.packageList });
                        }}
                        value={packageInfo.length}
                      />
                      {formError.packageList[packageIdx].length && (
                        <p className="form-label-text text-danger">
                          Please enter length, shouldn't less than or equal to 0
                        </p>
                      )}
                    </div>

                    <div className="col-3 mb-3">
                      <label
                        htmlFor={`width-${packageIdx}`}
                        className="form-label-text"
                      >
                        Width <span className="text-danger">*</span>
                      </label>
                      <input
                        id={`width-${packageIdx}`}
                        type="number"
                        placeholder="Enter width"
                        className="form-input-field w-100"
                        onChange={(e) => {
                          form.packageList[packageIdx].width = e.target.value
                          setForm({ ...form, packageList: form.packageList });
                        }}
                        value={packageInfo.width}
                      />
                      {formError.packageList[packageIdx].width && (
                        <p className="form-label-text text-danger">
                          Please enter width, shouldn't less than or equal to 0
                        </p>
                      )}
                    </div>

                    <div className="col-3 mb-3">
                      <label
                        htmlFor={`height-${packageIdx}`}
                        className="form-label-text"
                      >
                        Height <span className="text-danger">*</span>
                      </label>
                      <input
                        id={`height-${packageIdx}`}
                        type="number"
                        placeholder="Enter height"
                        className="form-input-field w-100"
                        onChange={(e) => {
                          form.packageList[packageIdx].height = e.target.value
                          setForm({ ...form, packageList: form.packageList });
                        }}
                        value={packageInfo.height}
                      />
                      {formError.packageList[packageIdx].height && (
                        <p className="form-label-text text-danger">
                          Please enter height, shouldn't less than or equal to 0
                        </p>
                      )}
                    </div>

                    <div className="col-3 mb-3">
                      <label
                        htmlFor={`quantity-${packageIdx}`}
                        className="form-label-text"
                      >
                        No of Pieces <span className="text-danger">*</span>
                      </label>
                      <input
                        id={`quantity-${packageIdx}`}
                        type="number"
                        placeholder="Enter no of pieces"
                        className="form-input-field w-100"
                        onChange={(e) => {
                          form.packageList[packageIdx].quantity = e.target.value
                          setForm({ ...form, packageList: form.packageList });
                        }}
                        value={packageInfo.quantity}
                      />
                      {
                        (totalEnteredQuantity() > parseInt(form.totalNoOfPackages)) &&
                        <p className="form-label-text text-danger">
                          Total entered quantity shouldn't more than total no of packages
                        </p>
                      }
                      {formError.packageList[packageIdx].quantity && (
                        <p className="form-label-text text-danger">
                          Please enter quantity, shouldn't less than or equal to 0
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-1 content-center">
                  {(form.packageList.length === 1 ||
                    form.packageList.length - 1 === packageIdx) && (
                    <i
                      className={`fa fa-plus text-primary
                        ${(totalEnteredQuantity() <= parseInt(form.totalNoOfPackages))
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'}`}
                      onClick={() =>
                        (totalEnteredQuantity() <= parseInt(form.totalNoOfPackages)) && addPackage()
                      }
                    />
                  )}
                  {form.packageList.length !== 1 && (
                    <i
                      className="fa fa-trash text-danger cursor-pointer ms-3"
                      onClick={() => removePackage(packageIdx)}
                    />
                  )}
                </div>
              </div>
            ))}

            <hr/>

            <div className="row">
              <div className="col-3 mb-3">
                <label
                  htmlFor="chargeable-weight"
                  className="form-label-text d-block mb-1"
                >
                  Chargeable Weight
                </label>
                <input
                  id="chargeable-weight"
                  type="number"
                  placeholder="Enter chargeable weight"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={getChargeableWeight()}
                />
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="rate-per-kg"
                  className="form-label-text d-block mb-1"
                >
                  Rate per KG
                </label>
                <input
                  id="rate-per-kg"
                  type="number"
                  placeholder="Enter rate per kg"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.ratePerKg}
                />
              </div>

              <div className="col-3 mb-3">
                <label
                  htmlFor="amount-to-be-collected"
                  className="form-label-text d-block mb-1"
                >
                  Amount to be Collected
                </label>
                <input
                  id="amount-to-be-collected"
                  type="number"
                  placeholder="Enter amount"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={getTotalCost()}
                />
              </div>
            </div>

            <div className="text-right mt-4">
              <button
                className="app-btn app-btn-primary-inverted me-2"
                onClick={() => onReset()}
              >
                Reset
              </button>
              <button
                className="app-btn app-btn-primary me-2"
                disabled={!formValidity()}
                onClick={() => openEstimationModal()}
              >
                Save
              </button>
              <button
                className="app-btn app-btn-primary"
                disabled={!uuid}
                onClick={() => navigate(`/invoice/${uuid}`)}
              >
                Invoice
              </button>
            </div>
          </Fragment>
        }
      </div>

      {
        isEstimationCostModalOpened && (
          <EstimationCostModal
            isOpen={isEstimationCostModalOpened}
            setIsOpen={setIsEstimationCostModalOpened}
            onSave={() => onSave()}
            totalCost={getTotalCost()}
          />
        )
      }

      {
        isFlightListModalOpened && (
          <FlightListModal
            isOpen={isFlightListModalOpened}
            setIsOpen={setIsFlightListModalOpened}
            onSelectFlight={(flightDetails: IFlight, shippingDate: string) =>
              onUpdateForm({ flightDetails, shippingDate }, 'selectedFlightList')}
            branch={form.branch[0].value}
            type={'outbound'}
          />
        )
      }

      {
        <AddCustomerModal
          isOpen={isAddCustomerModalOpened}
          setIsOpen={setIsAddCustomerModalOpened}
          onSave={() => {
            getCustomers();
            setIsAddCustomerModalOpened(false);
          }}
        />
      }
    </div>
  );
};

export default EditOutboundAwbPage;
