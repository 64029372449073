import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import { IFlight } from "../../interfaces/flight.interface";
import {
  createFlightAPI,
  getFlightsByIdAPI,
  updateFlightAPI,
} from "../../api-services/flight.api";
import { ISelectDropdown } from "../../interfaces/shipment.interface";
import { useAlertStore, useUserProfileStore } from "../../store/store";

interface IFlightFrom {
  flightNo: string;
  station: string;
  flightType: ISelectDropdown[];
  awsCode: string;
  aircraftType: string;
  departureTo: string;
  arrivalFrom: string;
  STA: string;
  STD: string;
  airline: string;
  frequency: string;
  airportCode: string;
}

const flightFormInit: IFlightFrom = {
  flightNo: "",
  station: "",
  flightType: [],
  awsCode: "",
  aircraftType: "",
  departureTo: "",
  arrivalFrom: "",
  STA: "",
  STD: "",
  airline: "",
  frequency: "",
  airportCode: "",
};

const FLIGHT_TYPE: ISelectDropdown[] = [
  {
    value: "Inbound",
    label: "Inbound",
  },
  {
    value: "Outbound",
    label: "Outbound",
  },
];

const AddFlightScreen = (): ReactElement => {
  const navigate = useNavigate();
  const { id, type } = useParams();

  const [form, setForm] = useState<IFlightFrom>(flightFormInit);
  const userProfile = useUserProfileStore((state) => state.userProfile);
  const updateAlertText = useAlertStore((state) => state.updateAlertText);

  const onUpdateForm = (e: any, type: string): void => {
    setForm({ ...form, [type]: e });
  };

  const getFlightById = (): void => {
    const params = { type: type };
    getFlightsByIdAPI(id, params).then((res: { payload: IFlight }) => {
      setForm({
        flightNo: res.payload.flightNo,
        station: res.payload.station,
        flightType: [
          { label: res.payload.flightType, value: res.payload.flightType },
        ],
        awsCode: res.payload.awsCode,
        aircraftType: res.payload.aircraftType,
        departureTo: res.payload.departureTo ? res.payload.departureTo : "",
        arrivalFrom: res.payload.arrivalFrom ? res.payload.arrivalFrom : "",
        STA: res.payload.arrivalTime ? res.payload.arrivalTime : "",
        STD: res.payload.departureTime ? res.payload.departureTime : "",
        airline: res.payload.airline,
        frequency: res.payload.frequency.join(""),
        airportCode: res.payload.airportCode,
      });
    });
  };

  const onUpdate = () => {
    const payload = {
      flightNo: form.flightNo,
      station: form.station,
      flightType: form.flightType[0].value,
      awsCode: form.awsCode,
      aircraftType: form.aircraftType,
      departureTo: form.departureTo,
      arrivalFrom: form.arrivalFrom,
      arrivalTime: form.STA,
      departureTime: form.STD,
      airline: form.airline,
      frequency: form.frequency.split("").map(Number),
      airportCode: form.airportCode.toUpperCase(),
    };

    updateFlightAPI(payload, id).then((res: any) => {
      if (res) {
        updateAlertText("Flight Updated Successfully", "success");
        navigate("/setting");
      }
    });
  };

  const onSave = () => {
    const payload = {
      flightNo: form.flightNo,
      station: form.station,
      flightType: form.flightType[0].value,
      awsCode: form.awsCode,
      aircraftType: form.aircraftType,
      departureTo: form.departureTo,
      arrivalFrom: form.arrivalFrom,
      arrivalTime: form.STA,
      departureTime: form.STD,
      airline: form.airline,
      frequency: form.frequency.split("").map(Number),
      airportCode: form.airportCode.toUpperCase(),
    };
    createFlightAPI(payload).then((res) => {
      if (res) {
        updateAlertText(res.message, "success");
        navigate("/setting");
      }
    });
  };

  const formValidity = (): boolean => {
    return (
      form.flightNo !== "" &&
      form.station !== "" &&
      form.awsCode !== "" &&
      form.aircraftType !== "" &&
      form.frequency !== "" &&
      form.airportCode !== "" &&
      form.airline !== "" &&
      form.flightType.length !== 0
    );
  };

  const resetHandler = () => {
    if (!!id) {
      getFlightById();
    } else {
      setForm({
        flightNo: "",
        station: "",
        flightType: [],
        awsCode: "",
        aircraftType: "",
        departureTo: "",
        arrivalFrom: "",
        STA: "",
        STD: "",
        airline: "",
        frequency: "",
        airportCode: "",
      });
    }
  };

  useEffect(() => {
    setForm({ ...form, ["station"]: userProfile.branch });
  }, [userProfile.branch]);

  useEffect(() => {
    if (!!id) {
      getFlightById();
    }
  }, [id]);

  return (
    <div className="content">
      <div className="card">
        <div className="card-title mb-3">Flight Info</div>
        <div className="row ">
          <div className="col-md-4">
            <div className="row d-flex flex-column">
              <div className="col-md-10 mb-3">
                <label
                  id="flight-number"
                  className="form-label-text d-block mb-1"
                >
                  Flight Number <span className="text-danger">*</span>
                </label>
                <input
                  id="flight-number"
                  type="text"
                  placeholder="Enter Flight Number"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, "flightNo")}
                  value={form.flightNo}
                />
              </div>
              <div className="col-md-10 mb-3">
                <label
                  id="flight-sector"
                  className="form-label-text d-block mb-1"
                >
                  Station <span className="text-danger">*</span>
                </label>
                <input
                  id="flight-sector"
                  type="text"
                  placeholder="Enter Sector"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, "station")}
                  value={form.station}
                  disabled
                />
              </div>

              <div className="col-md-10 mb-3">
                <label htmlFor="flight-type" className="form-label-text">
                  FlightType <span className="text-danger">*</span>
                </label>
                <Select
                  id="flight-type"
                  options={FLIGHT_TYPE}
                  isSearchable
                  placeholder="Select flight type"
                  onChange={(e) => onUpdateForm([e], "flightType")}
                  value={form.flightType}
                  isDisabled={!!id}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row d-flex flex-column">
              <div className="col-md-10 mb-3">
                <label
                  id="flight-awsCode"
                  className="form-label-text d-block mb-1"
                >
                  Aws Code <span className="text-danger">*</span>
                </label>
                <input
                  id="flight-awsCode"
                  type="text"
                  placeholder="Enter aws Code"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, "awsCode")}
                  value={form.awsCode}
                />
              </div>
              <div className="col-md-10 mb-3">
                <label
                  id="flight-aircraft"
                  className="form-label-text d-block mb-1"
                >
                  Aircraft Type <span className="text-danger">*</span>
                </label>
                <input
                  id="flight-aircraft"
                  type="text"
                  placeholder="Enter AirCraft Type"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, "aircraftType")}
                  value={form.aircraftType}
                />
              </div>
              {form.flightType.length > 0 &&
              form.flightType[0].value === "Inbound" ? (
                <div className="col-md-10 mb-3">
                  <label
                    id="flight-std"
                    className="form-label-text d-block mb-1"
                  >
                    Arrialval from <span className="text-danger">*</span>
                  </label>
                  <input
                    id="arrival-from"
                    type="text"
                    placeholder="Enter Arriaval From"
                    className="form-input-field w-100"
                    onChange={(e) =>
                      onUpdateForm(e.target.value, "arrivalFrom")
                    }
                    value={form.arrivalFrom}
                  />
                </div>
              ) : (
                <div className="col-md-10 mb-3">
                  <label
                    id="flight-std"
                    className="form-label-text d-block mb-1"
                  >
                    Departure To <span className="text-danger">*</span>
                  </label>
                  <input
                    id="departure-to"
                    type="text"
                    placeholder="Enter Departure To"
                    className="form-input-field w-100"
                    onChange={(e) =>
                      onUpdateForm(e.target.value, "departureTo")
                    }
                    value={form.departureTo}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="row d-flex flex-column">
              {form.flightType.length > 0 &&
              form.flightType[0].value === "Inbound" ? (
                <div className="col-md-10 mb-3">
                  <label
                    id="flight-sta"
                    className="form-label-text d-block mb-1"
                  >
                    STA <span className="text-danger">*</span>
                  </label>
                  <input
                    id="flight-sta"
                    type="time"
                    placeholder="Enter STA"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, "STA")}
                    value={form.STA}
                  />
                </div>
              ) : (
                <div className="col-md-10 mb-3">
                  <label
                    id="flight-std"
                    className="form-label-text d-block mb-1"
                  >
                    STD <span className="text-danger">*</span>
                  </label>
                  <input
                    id="flight-std"
                    type="time"
                    placeholder="Enter STD"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, "STD")}
                    value={form.STD}
                  />
                </div>
              )}
              <div className="col-md-10 mb-3">
                <label id="airline" className="form-label-text d-block mb-1">
                  Airline <span className="text-danger">*</span>
                </label>
                <input
                  id="airline"
                  type="text"
                  placeholder="Enter Airline"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, "airline")}
                  value={form.airline}
                />
              </div>
              <div className="col-md-10 mb-3">
                <label id="frequency" className="form-label-text d-block mb-1">
                  Frequnecy <span className="text-danger">*</span>
                </label>
                <input
                  id="frequency"
                  type="text"
                  placeholder="Enter Frequency"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, "frequency")}
                  value={form.frequency}
                />
              </div>
              <div className="col-md-10 mb-3">
                <label
                  id="airportCode"
                  className="form-label-text d-block mb-1"
                >
                  Airpot Code <span className="text-danger">*</span>
                </label>
                <input
                  id="airportCode"
                  type="text"
                  placeholder="Enter Frequency"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, "airportCode")}
                  value={form.airportCode}
                />
              </div>
            </div>
          </div>
        </div>
        <footer
          className="text-center text-lg-start fixed-bottom"
          style={{
            backgroundColor: "#ffffff",
            paddingBottom: "0.5rem",
            paddingRight: "1rem",
            zIndex: 1,
          }}
        >
          <div className="text-right mt-4">
            <button
              className="app-btn app-btn-primary-inverted me-3"
              onClick={() => resetHandler()}
            >
              Reset
            </button>
            <button
              className="app-btn app-btn-primary"
              disabled={!formValidity()}
              onClick={() => (!!id ? onUpdate() : onSave())}
            >
              {!!id ? "Update Flight" : "Add Flight"}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AddFlightScreen;
