import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ISidebarData } from './Layout';
import { useUserProfileStore } from '../../store/store';

interface ILocation {
  pathname: string;
}

interface IProps {
  sidebarData: ISidebarData[];
}

const SidebarContent = (props: IProps): ReactElement => {
  let navigate = useNavigate();
  let { pathname }: ILocation = useLocation();

  const [sidebarData, setSidebarData] = useState<ISidebarData[]>([]);

  const userProfile = useUserProfileStore((state) => state.userProfile);

  const routeChange = (path: string): void => {
    [path].indexOf(path) !== -1 && navigate(path);
  };

  const toggleMenu = (idx: number): void => {
    const data = [...sidebarData];
    data[idx].expanded = !data[idx].expanded;
    setSidebarData(data);
  };

  const isActivePath = (item: ISidebarData): boolean => {
    if (!!item.link) {
      return pathname.substring(1) === item.link || pathname.substring(1) == `add-${item.link}`;
    } else {
      return item.children.some((subItem) => pathname.substring(1) === subItem.link);
    }
  };

  useEffect(() => {
    /*if (userProfile.role === 'USER') {
      const result = props.sidebarData.filter(
        (data) => data.title !== 'Setting'
      );
      setSidebarData(() => result);
    } else {
      setSidebarData(props.sidebarData);
    }*/
    setSidebarData(props.sidebarData);
  }, [userProfile]);

  return (
    <div id="sidebar-menu">
      <ul className="menu-bar list-unstyled">
        {sidebarData.map((item, itemIdx: number) => (
          <li
            key={itemIdx}
            className={isActivePath(item) ? 'active' : ''}
            onClick={() =>
              item.children.length === 0
                ? routeChange(`/${item.link}`)
                : toggleMenu(itemIdx)
            }
          >
            <a
              href="javascript:"
              className={`d-flex justify-content-between ${
                itemIdx !== 0 && itemIdx !== 3 ? 'disabled-bar' : ''
                }`}
            >
              <div>
                <i className={`${item.icon}`}/>
                <span>{item.title}</span>
              </div>
              <div className="sidebar-more-menu">
                {item.children.length > 0 && (
                  <i
                    className={`ri-arrow-drop-${
                      item.expanded ? 'up' : 'down'
                      }-line`}
                  />
                )}
              </div>
            </a>

            {item.expanded && (
              <ul className="sub-menu">
                {item.children.map((subitem, subitemIdx: number) => (
                  <li
                    key={subitemIdx}
                    className={`${
                      pathname.includes(subitem.link) ? 'active' : ''
                      } border-0`}
                    onClick={() => routeChange(`/${subitem.link}`)}
                  >
                    <a href="javascript:">
                      <i className={`${subitem.icon}`}/>
                      <span>{subitem.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarContent;
