import { create } from 'zustand';
import { IUserProfile } from '../interfaces/user.interface';

/*Sidebar Store*/
type SidebarState = {
  leftSideBarType: string;
}

type SidebarAction = {
  toggleSidebar: (type: string) => void;
}

export const useSidebarStore = create<SidebarState & SidebarAction>((set) => ({
  leftSideBarType: 'condensed',
  toggleSidebar: (type: string) => set({ leftSideBarType: type }),
}));

/*Alert container Store*/
type AlertState = {
  alertText: {
    text: string,
    type?: string,
  };
}

type AlertAction = {
  updateAlertText: (text: string, type?: string,) => void;
}

export const useAlertStore = create<AlertState & AlertAction>((set) => ({
  alertText: { text: '', type: '' },
  updateAlertText: (text: string, type?: string) => set({ alertText: { text, type } }),
}));

/*Loader Store*/
type LoaderState = {
  showLoader: boolean;
}

type LoaderAction = {
  updateLoader: (state: boolean) => void;
}

export const useLoaderStore = create<LoaderState & LoaderAction>((set) => ({
  showLoader: false,
  updateLoader: (state: boolean) => set({ showLoader: state }),
}));

/*User Profile Store*/
type UserProfileState = {
  userProfile: IUserProfile;
}

type UserProfileAction = {
  updateUserProfile: (data: IUserProfile) => void;
}

const userInfoInit = {
  branch: '',
  createdAt: '',
  deletedAt: '',
  email: '',
  phone: '',
  role: '',
  station: '',
  updatedAt: '',
  userName: '',
  uuid: '',
};

export const useUserProfileStore = create<UserProfileState & UserProfileAction>((set) => ({
  userProfile: userInfoInit,
  updateUserProfile: (data: IUserProfile) => set({ userProfile: data }),
}));
