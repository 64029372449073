export const flightInit = {
  uuid: '',
  airportCode: '',
  aircraftType: '',
  airline: '',
  awsCode: '',
  arrivalFrom: '',
  arrivalTime: '',
  departureTime: '',
  departureTo: '',
  flightNo: '',
  frequency: [],
  id: 0,
  station: '',
  bookedPcs: 0,
  bookedWt: 0,
  flightType: '',
  shippingDate: '',
};

export interface IFlight {
  uuid: string,
  airportCode: string,
  aircraftType: string,
  airline: string,
  awsCode: string,
  arrivalFrom: string,
  arrivalTime: string,
  departureTime: string,
  departureTo: string,
  flightNo: string,
  frequency: number[],
  id: number,
  station: string,
  bookedPcs: number,
  bookedWt: number,
  flightType: string,
  shippingDate: string,
  flightDetails?: IFlight,
}
