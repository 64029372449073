import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DeleteConfirmationModal from './DeleteConfirmationModal';

import { ICustomer } from '../../interfaces/customer.interface';
import {
  deleteCustomerAPI,
  getCustomersAPI,
} from '../../api-services/customer.api';
import { dateTimeFormat } from '../../utils/Utils';

const CustomerScreen = (): ReactElement => {
  let navigate = useNavigate();

  const [customerList, setCustomerList] = useState<ICustomer[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('');

  const handleShowDeleteModal = (id: string) => {
    setSelectedCustomer(() => id);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleDelete = () => {
    deleteCustomerAPI(selectedCustomer).then((res) => {
      if (res) {
        getAllCustomer();
        handleCloseDeleteModal();
      }
    });
  };

  const getAllCustomer = () => {
    getCustomersAPI().then((res: any) => {
      if (res) {
        setCustomerList(() => res.payload);
      }
    });
  };

  useEffect(() => {
    getAllCustomer();
  }, []);

  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-md-10">
            <div className="card-title mb-3">Customer</div>
          </div>
          <div className="col-md-2 text-right">
            <button
              className="app-btn app-btn-primary"
              onClick={() => navigate('/add-customer')}
            >
              Add Customer
            </button>
          </div>
          <div className="col-md-12">
            <div className="table-responsive mt-3">
              <table className="mb-0 w-100">
                <thead>
                <tr>
                  <th>NAME</th>
                  <th>Flat/Room/Door/Block No</th>
                  <th>Locality or Area Name</th>
                  <th>Town/City</th>
                  <th>Pin Code</th>
                  <th>State</th>
                  <th>Phone Number</th>
                  <th>Email ID</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {customerList.map((data, dataIdx: number) => (
                  <tr key={dataIdx}>
                    <td>{data.name}</td>
                    <td>{data.blockNo}</td>
                    <td>{data.locality}</td>
                    <td>{data.town}</td>
                    <td>{data.pinCode}</td>
                    <td>{data.state}</td>
                    <td>{data.phone}</td>
                    <td>{data.email}</td>
                    <td>{dateTimeFormat(data.updatedAt)}</td>
                    <td className="text-center">
                      <i className="fa fa-pencil-alt text-primary cursor-pointer me-3"
                         onClick={() => navigate(`/update-customer/${data.uuid}`)}/>
                      <i
                        className="fa fa-trash text-danger cursor-pointer me-3"
                        onClick={() => handleShowDeleteModal(data.uuid)}
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default CustomerScreen;
