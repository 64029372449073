import { HandlerResponse, deleteRequest, getRequest, postRequest, putRequest } from './api';

export const createCustomerAPI = async (payload: any): Promise<any> => {
  return postRequest(`${process.env.REACT_APP_API_ENDPOINT}/users/add-customer`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const getCustomersAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/users/getAllCustomer`)
    .then((resp: any) => HandlerResponse(resp));
};

export const deleteCustomerAPI = async (uuid: string): Promise<any> => {
  return deleteRequest(`${process.env.REACT_APP_API_ENDPOINT}/users/delete-customer?customerUUID=${uuid}`,)
    .then((resp: any) => HandlerResponse(resp));
};

export const getCustomerByIdAPI = async (id: string): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/users/customer/${id}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const updateCustomerAPI = async (payload: any): Promise<any> => {
  return putRequest(`${process.env.REACT_APP_API_ENDPOINT}/users/update-customer`, payload)
    .then((resp: any) => HandlerResponse(resp));
};
