import React, { ReactElement, useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import avatar from '../../assets/images/users/avatar-1.jpg';

import { handleLogout } from '../../utils/Utils';

import { useUserProfileStore } from '../../store/store';

import { IUserProfile } from '../../interfaces/user.interface';
import { getUserProfileAPI } from '../../api-services/user.api';

const userInfoInit = {
  branch: '',
  createdAt: '',
  deletedAt: '',
  email: '',
  phone: '',
  role: '',
  station: '',
  updatedAt: '',
  userName: '',
  uuid: '',
};

const ProfileMenu = (): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUserProfile>(userInfoInit);

  const updateUserProfile = useUserProfileStore(state => state.updateUserProfile);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = (): void => {
    getUserProfileAPI()
      .then((res) => {
        if (!!res) {
          setUserInfo(res.payload);
          updateUserProfile(res.payload);
        }
      });
  };

  const toggle = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      className="d-inline-block user-dropdown"
    >
      <DropdownToggle
        tag="button"
        className="btn header-item waves-effect"
        id="page-header-user-dropdown"
      >
        <img
          className="rounded-circle header-profile-user me-1"
          src={avatar}
          alt="Header Avatar"
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem href="#">
          <i className="ri-user-line align-middle me-1"/>
          {userInfo.userName} ({userInfo.role})
        </DropdownItem>
        <DropdownItem divider/>
        <DropdownItem
          className="text-danger"
          href="#"
          onClick={() => handleLogout()}
        >
          <i className="ri-shut-down-line align-middle me-1 text-danger"/>{' '}
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileMenu;
