import { ObjToQueryString } from '../utils/Utils';

import { IShipmentPayload } from '../interfaces/shipment.interface';
import { deleteRequest, getRequest, HandlerResponse, postRequest, putRequest } from './api';

export const getHandlingCodesAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/handlingCodes${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getShipmentsAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/shipments`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getShipmentByIdAPI = async (id: string): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/shipments/${id}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const createShipmentAPI = async (payload: IShipmentPayload): Promise<any> => {
  return postRequest(`${process.env.REACT_APP_API_ENDPOINT}/shipments`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const updateShipmentAPI = async (id: string, payload: IShipmentPayload): Promise<any> => {
  return putRequest(`${process.env.REACT_APP_API_ENDPOINT}/shipments/${id}`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const getAllShipmentByFlightAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/shipments/getAllShipmentByFlight${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const checkFlightWithSameDateAPI = async (queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/shipments/checkFlightWithSameDate${queryString}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const deleteShipmentAPI = async (uuid: string): Promise<any> => {
  return deleteRequest(`${process.env.REACT_APP_API_ENDPOINT}/shipments/${uuid}`,)
    .then((resp: any) => HandlerResponse(resp));
};
