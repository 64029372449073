import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';

import { BRANCH_LIST } from '../../constants/Constants';

import { IDropdown } from '../../interfaces/common.interface';
import { createUserAPI } from '../../api-services/user.api';

interface IForm {
  userName: string;
  email: string;
  phone: string;
  branch: IDropdown[];
  password: string;
  confirmPassword: string;
}

const formInit: IForm = {
  userName: '',
  email: '',
  phone: '',
  branch: [],
  password: '',
  confirmPassword: '',
};

const formErrorInit = {
  userName: false,
  email: false,
  phone: false,
  branch: false,
  password: false,
  confirmPassword: false,
};

const AddUser = (): ReactElement => {
  const navigate = useNavigate();

  const [form, setForm] = useState<IForm>(formInit);
  const [formError, setFormError] = useState<any>(formErrorInit);
  const [checkFormValidity, setCheckFormValidity] = useState<boolean>(true);

  const onUpdateForm = (e: any, type: string): void => {
    const updatedForm: any = { ...form };
    const updatedFormError = { ...formError };

    if (type === 'confirmPassword') {
      if (updatedForm.password !== e) {
        updatedFormError.confirmPassword = true;
      } else {
        updatedFormError.confirmPassword = false;
      }
    }
    updatedForm[type] = e;

    setForm(updatedForm);
    setFormError(updatedFormError);
  };

  const formValidation = (): void => {
    let formErr = { ...formError };
    if (form.userName === '') {
      formErr.userName = true;
    }

    if (form.phone === '' || form.phone.length !== 10) {
      formErr.phone = true;
    }

    if (form.email === '') {
      formErr.email = true;
    }
    if (form.branch.length === 0) {
      formErr.branch = true;
    }
    if (form.password === '') {
      formErr.password = true;
    }
    // Check if there are any errors; if so, the form is not valid
    const isValid = Object.values(formErr).some((error) => error);
    setCheckFormValidity(() => isValid);
    setFormError(formErr);
  };

  const onReset = (): void => {
    setForm(formInit);
    setFormError(formErrorInit);
  };

  const onCreate = (): void => {
    formValidation();
    if (checkFormValidity) {
      return;
    } else {
      const payload = {
        userName: form.userName,
        email: form.email,
        phone: form.phone,
        role: 'USER',
        password: form.password,
        branch: form.branch[0].name
      }

      createUserAPI(payload)
        .then((res: any) => {
          if (res) {
            navigate('/setting')
          }
        })
    }
  };

  return (
    <>
      <div className="content">
        <div className="card">
          <div className="card-title mb-3">User Info</div>
          <div className="row d-flex flex-column">
            <div className="row">
              <div className="col-md-6">
                <div className="row d-flex flex-column">
                  <div className="col-md-6 mb-3">
                    <label
                      id="user-userName"
                      className="form-label-text d-block mb-1"
                    >
                      User Name <span className="text-danger">*</span>
                    </label>
                    <input
                      id="user-userName"
                      type="text"
                      placeholder="Enter User userName"
                      className="form-input-field w-100"
                      onChange={(e) => onUpdateForm(e.target.value, 'userName')}
                      value={form.userName}
                    />
                    {formError.userName && (
                      <p className="form-label-text text-danger">
                        Please enter User userName
                      </p>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <label id="user-branch" className="form-label-text">
                      Select Branch <span className="text-danger">*</span>
                    </label>
                    <Multiselect
                      id="user-branch"
                      options={BRANCH_LIST}
                      selectedValues={form.branch}
                      onSelect={(e) => onUpdateForm(e, 'branch')}
                      onRemove={(e) => onUpdateForm(e, 'branch')}
                      displayValue="name"
                      avoidHighlightFirstOption={true}
                      showArrow
                      singleSelect={true}
                    />
                    {formError.branch && (
                      <p className="form-label-text text-danger">
                        Please select a branch
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      id="user-email"
                      className="form-label-text d-block mb-1"
                    >
                      User Email <span className="text-danger">*</span>
                    </label>
                    <input
                      id="user-email"
                      type="email"
                      placeholder="Enter User Email"
                      className="form-input-field w-100"
                      onChange={(e) => onUpdateForm(e.target.value, 'email')}
                      value={form.email}
                    />
                    {formError.email && (
                      <p className="form-label-text text-danger">
                        Please enter User Email
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row d-flex flex-column">
                  <div className="col-md-6 mb-3">
                    <label
                      id="user-phone"
                      className="form-label-text d-block mb-1"
                    >
                      User Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      id="user-phone"
                      type="number"
                      placeholder="Enter user phone"
                      className="form-input-field w-100"
                      onChange={(e) => onUpdateForm(e.target.value, 'phone')}
                      value={form.phone}
                    />
                    {formError.phone && (
                      <p className="form-label-text text-danger">
                        Please enter user phone
                      </p>
                    )}
                    {!!form.phone && form.phone.length != 10 && (
                      <p className="form-label-text text-danger">
                        Please enter valid phone number
                      </p>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label
                      id="user-password"
                      className="form-label-text d-block mb-1"
                    >
                      Password <span className="text-danger">*</span>
                    </label>
                    <input
                      id="user-password"
                      type="password"
                      placeholder="Enter password"
                      className="form-input-field w-100"
                      onChange={(e) => onUpdateForm(e.target.value, 'password')}
                      value={form.password}
                    />
                    {formError.password && (
                      <p className="form-label-text text-danger">
                        Please enter password
                      </p>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label
                      id="user-confirmPassword"
                      className="form-label-text d-block mb-1"
                    >
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <input
                      id="user-confirmPassword"
                      type="password"
                      placeholder="Enter Confirm Password"
                      className="form-input-field w-100"
                      onChange={(e) =>
                        onUpdateForm(e.target.value, 'confirmPassword')
                      }
                      value={form.confirmPassword}
                    />
                    {formError.confirmPassword && (
                      <p className="form-label-text text-danger">
                        password and confirm password not matched
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer
          className="text-center text-lg-start fixed-bottom"
          style={{
            backgroundColor: '#ffffff',
            paddingBottom: '0.5rem',
            paddingRight: '1rem',
          }}
        >
          <div className="text-right mt-4">
            <button
              className="app-btn app-btn-primary-inverted me-3"
              onClick={() => onReset()}
            >
              Reset
            </button>
            <button
              className="app-btn app-btn-primary"
              onClick={() => onCreate()}
            >
              Create
            </button>
          </div>
        </footer>
      </div>
    </>
  );
};

export default AddUser;
