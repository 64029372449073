import React, { ReactElement } from 'react';

import { useAlertStore } from '../../store/store';

import PageLoader from '../common/loader/PageLoader';
import Header from './Header';
import Sidebar from './Sidebar';
import AlertContainer from '../common/alert-container/AlertContainer';

interface Props {
  children: any;
}

export interface ISidebarData {
  title: string;
  icon: string;
  link: string;
  expanded: boolean;
  children: ISidebarData[];
}

const SidebarData: ISidebarData[] = [
  {
    title: 'Outbound AWB',
    icon: 'ri-flight-takeoff-line',
    link: 'outbound-awb',
    expanded: false,
    children: [],
  },
  {
    title: 'Outbound Manifest',
    icon: 'ri-user-2-line',
    link: 'outbound-manifest',
    expanded: false,
    children: [],
  },
  {
    title: 'Outbound Customer Invoice',
    icon: 'ri-bill-line',
    link: 'outbound-customer-invoice',
    expanded: false,
    children: [],
  },
  {
    title: 'Inbound Manifest',
    icon: 'ri-user-2-line',
    link: 'inbound-manifest',
    expanded: false,
    children: [],
  },
  {
    title: 'Inbound AWB',
    icon: 'ri-flight-land-line',
    link: 'inbound-awb',
    expanded: false,
    children: [],
  },
  {
    title: 'Inbound Customer Invoice',
    icon: 'ri-bill-line',
    link: 'inbound-customer-invoice',
    expanded: false,
    children: [],
  },
  {
    title: 'Customer',
    icon: 'ri-user-2-line',
    link: 'customer',
    expanded: false,
    children: [],
  },
  {
    title: 'Setting',
    icon: 'ri-settings-line',
    link: 'setting',
    expanded: false,
    children: [],
  },
];

const Layout = (props: Props): ReactElement => {
  const updateAlertText = useAlertStore((state) => state.updateAlertText);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <PageLoader/>

        <Header/>

        <Sidebar sidebarData={SidebarData}/>

        <div className="main-content">
          <AlertContainer onDismiss={() => updateAlertText('')}/>
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
