import React, { ReactElement, useEffect } from 'react';
import { Alert } from 'reactstrap';

import { useAlertStore } from '../../../store/store';

interface IProps {
  onDismiss: () => void;
}

const AlertContainer = (props: IProps): ReactElement | null => {
  const { onDismiss } = props;

  const alertText = useAlertStore(state => state.alertText);
  const updateAlertText = useAlertStore(state => state.updateAlertText);


  
  useEffect(() => {
    const timeId = setTimeout(() => {
      updateAlertText('');
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [alertText]);

  if (alertText.text === '') {
    return null;
  }

  return (
    <Alert
      color={alertText.type || 'success'}
      isOpen={!!alertText.text}
      toggle={onDismiss}
    >
      <div className='alert-content'>
        {alertText.text}
      </div>
    </Alert>
  );
};

export default AlertContainer;
