export const handleLogout = (): void => {
  sessionStorage.removeItem(`${process.env.REACT_APP_NAME}_TOKEN`);
  window.location.replace('/');
};

export const parseJwt = (token: string): Object => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const ObjToQueryString = (queryParams: any) => {
  if (!queryParams || Object.keys(queryParams).length === 0) {
    return '';
  }

  const keyValuePairs = Object.entries(queryParams)
    .map(([key, value]: any) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);

  return '?' + keyValuePairs.join('&');
};

export const dateFormat = (date: string): string => {
  const d = new Date(date);
  return [
    d.getDate().toString().length === 1 ? ('0' + d.getDate()) : d.getDate(),
    (d.getMonth() + 1).toString().length === 1 ? ('0' + (d.getMonth() + 1)) : (d.getMonth() + 1),
    d.getFullYear(),
  ].join('-');
};

export const dateTimeFormat = (date: string): string => {
  const d = new Date(date);
  const dateStr = [
    d.getDate().toString().length === 1 ? ('0' + d.getDate()) : d.getDate(),
    (d.getMonth() + 1).toString().length === 1 ? ('0' + (d.getMonth() + 1)) : (d.getMonth() + 1),
    d.getFullYear(),
  ].join('-');
  const timeStr = [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');

  return dateStr + ' ' + timeStr;
};

export const validatePan = (pan: string): boolean => {
  const regex = /[A-Z]{5}[0-9]{4}[A-Z]/;
  return regex.test(pan);
};

export const validateGst = (gst: string): boolean => {
  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][0-9A-Z][Z][0-9A-Z]$/;
  return regex.test(gst);
};
