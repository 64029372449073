import React, { ReactElement, useEffect, useState, Fragment } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import './App.scss';

import { authProtectedRoutes, nonProtectedRoutes } from './routes/Routes';

import Layout from './components/layout/Layout';

const App = (): ReactElement => {
  const navigate = useNavigate();

  const userToken = sessionStorage.getItem(`${process.env.REACT_APP_NAME}_TOKEN`);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!userToken);

  useEffect(() => {
    checkUserToken();
  }, [JSON.stringify(userToken)]);

  const checkUserToken = (): void => {
    if (!userToken) {
      setIsLoggedIn(false);
      navigate('/sign-in');
    } else {
      setIsLoggedIn(true);
    }
  };

  return (
    <Fragment>
      {isLoggedIn ? (
        <Layout>
          <Routes>
            {authProtectedRoutes.map((route: any, idx: number) => (
              <Route key={idx} path={route.path} element={route.element}/>
            ))}
          </Routes>
        </Layout>
      ) : (
        <Routes>
          {nonProtectedRoutes.map((route: any, idx: number) => (
            <Route key={idx} path={route.path} element={route.element}/>
          ))}
        </Routes>
      )}
    </Fragment>
  );
};

export default App;
