import React, { ReactElement } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean, isResetRequired: boolean) => void;
}

const SameAwbNoModal = (props: IProps): ReactElement => {
  const { isOpen, setIsOpen } = props;

  return (
    <Modal isOpen={isOpen} centered={true}>
      <ModalBody>
        <h2>The Awb no is already exist, Are you sure you want to continue with same?</h2>
      </ModalBody>

      <ModalFooter>
        <p
          className="form-label-text text-danger cursor-pointer me-4"
          onClick={() => setIsOpen(false, true)}
        >
          Cancel
        </p>
        <button
          className="app-btn app-btn-primary"
          onClick={() => setIsOpen(false, false)}
        >
          Continue
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default SameAwbNoModal;
