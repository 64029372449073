import React, { ReactElement } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface IProps {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  onSave: () => void,
  totalCost: number,
}

const EstimationCostModal = (props: IProps): ReactElement => {
  const { isOpen, setIsOpen, onSave, totalCost, } = props;

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
    >
      <ModalHeader>
        <p className="modal-title">
          Estimation Cost
        </p>
      </ModalHeader>

      <ModalBody>
        <div className="row">
          <div className="col-6">
            <p className="modal-body-text">Shipping Cost:</p>
          </div>
          <div className="col-6">
            <p className="modal-body-text">₹{totalCost}</p>
          </div>

          <div className="col-6">
            <p className="modal-body-text">Tax & Duty:</p>
          </div>
          <div className="col-6">
            <p className="modal-body-text">₹0.00</p>
          </div>

          <div className="col-6">
            <p className="modal-body-text">Insurance:</p>
          </div>
          <div className="col-6">
            <p className="modal-body-text">₹0.00</p>
          </div>

          <div className="col-6">
            <p className="modal-body-text">Return Cost :</p>
          </div>
          <div className="col-6">
            <p className="modal-body-text">₹0.00</p>
          </div>
        </div>

        <div className="row border-top mt-2 pt-2">
          <div className="col-6">
            <p className="modal-body-text">TOTAL COST :</p>
          </div>
          <div className="col-6">
            <p className="modal-body-text">₹{totalCost}</p>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <p
          className="form-label-text text-danger cursor-pointer me-4"
          onClick={() => setIsOpen(false)}
        >Cancel</p>
        <button
          className="app-btn app-btn-primary"
          onClick={() => {
            setIsOpen(false);
            onSave();
          }}
        >Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default EstimationCostModal;
