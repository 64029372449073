import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./InboundManifest.module.scss";

import { dateFormat } from "../../utils/Utils";

import { getInboundManifestByIdAPI } from "../../api-services/inboundManifest.api";

const InboundManifestInvoicePage = (): ReactElement => {
  const { id } = useParams();

  const [dataSource, setDataSource] = useState<any>();

  useEffect(() => {
    getInboundManifestById();
  }, []);

  const getInboundManifestById = (): void => {
    getInboundManifestByIdAPI(id || "").then((res: { payload: any }) => {
      setDataSource(res.payload);
    });
  };

  const totalMftPcsForLocal = (): number =>
    dataSource.Local.reduce(
      (acc: number, value: any) => acc + parseInt(value.mftPcs === "" ? 0 :value.mftPcs),
      0
    );

  const totalMftWtForLocal = (): number =>
    dataSource.Local.reduce(
      (acc: number, value: any) => acc + parseFloat(value.mftWt === "" ? 0 :value.mftWt),
      0
    );

  const totalChargeableWeightForLocal = (): number =>
    dataSource.Local.reduce(
      (acc: number, value: any) => acc + parseFloat(value.chWt === "" ? 0 : value.chWt),
      0
    );
  const totalMftPcsForTansit = (): number =>
    dataSource.Transit.reduce(
      (acc: number, value: any) => acc + parseInt(value.mftPcs === "" ? 0 :value.mftPcs),
      0
    );

  const totalMftWtForTransit = (): number =>
    dataSource.Transit.reduce(
      (acc: number, value: any) => acc + parseFloat(value.mftWt === "" ? 0 :value.mftWt),
      0
    );

  const totalChargeableWeightForTransit = (): number =>
    dataSource.Transit.reduce(
      (acc: number, value: any) =>
        acc + parseFloat(value.chWt === "" ? 0 : value.chWt),
      0
    );

    const totalGrossWeight = () => {
      const totalLocal = dataSource.Local.length > 0 ? totalMftWtForLocal() : 0;
      const totalTransit = dataSource.Transit.length > 0 ? totalMftWtForTransit() : 0;
      
      const result = totalLocal + totalTransit;
  
      return result.toFixed(2);
  };

  return (
    <Fragment>
      {!!dataSource &&
        (dataSource.Local.length > 0 || dataSource.Transit.length > 0) && (
          <div className="content">
            <div className="card">
              <div className="row">
                <div className="col-12 text-center">
                  <p className={styles["header-text"]} style={{fontSize:'20px'}}>CARGO MANIFEST</p>
                  <p className={styles["sub-header-text"]}>(I.C.A.O ANNEX 9)</p>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6 fw-bold">Flight No & Date:</div>
                    <div className="col-6">
                      {dataSource.flightDetails.flightNo} / {" "}
                      {dateFormat(dataSource.date)}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Point of Loading:</div>
                    <div className="col-6">{dataSource.pointOfLoading}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Point of Unloading:</div>
                    <div className="col-6">{dataSource.pointOfUploading}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Total Pcs:</div>
                    <div className="col-6">{dataSource.totalPcs}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6 fw-bold">Owner/Operator:</div>
                    <div className="col-6">
                      {dataSource.flightDetails.airline}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Tail No:</div>
                    <div className="col-6">{dataSource.tailNo}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 fw-bold">Total AWB:</div>
                    <div className="col-6">{dataSource.totalAwb}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 ">Gross Weight:</div>
                    <div className="col-6">
                      {
                        //dataSource.grossWt
                        totalGrossWeight()
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <p className={styles["header-text"]}>LOCAL</p>
                <div className="col-12 p-0">
                  <div
                    className={`${styles["invoice-table"]} table-responsive`}
                  >
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th className="fw-bold">CART/ULD</th>
                          <th className="fw-bold">AWB No</th>
                          <th className="fw-bold">ORG</th>
                          <th className="fw-bold">DEST</th>
                          <th className="fw-bold">SHC</th>
                          <th className="fw-bold">COMM DESC</th>
                          <th className="fw-bold">MFT PCS</th>
                          <th className="fw-bold">MFT WT</th>
                          <th className="fw-bold">CH WT</th>
                          <th className="fw-bold">REMARKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataSource.Local.map(
                          (shipment: any, shipmentIdx: number) => (
                            <tr key={shipmentIdx}>
                              <td>{shipment.cart_uld}</td>
                              <td>{shipment.awbNo}</td>
                              <td>{shipment.org}</td>
                              <td>{shipment.dest}</td>
                              <td>{shipment.ssc}</td>
                              <td>{shipment.commDesc}</td>
                              <td>{shipment.mftPcs}</td>
                              <td>{shipment.mftWt}</td>
                              <td>{shipment.chWt}</td>
                              <td className={shipment.remark ? 'print-remark' :''}>{shipment.remark}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={6}>Total:</td>
                          <td>{totalMftPcsForLocal()}</td>
                          <td>{totalMftWtForLocal().toFixed(2)}</td>
                          <td>{totalChargeableWeightForLocal().toFixed(2)}</td>
                          <td />
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              {dataSource.Transit.length > 0 && (
                <div className="row mt-5">
                  <p className={styles["header-text"]}>TRANSIT</p>
                  <div className="col-12 p-0">
                    <div
                      className={`${styles["invoice-table"]} table-responsive`}
                    >
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th className="fw-bold">CART/ULD</th>
                            <th className="fw-bold">AWB No</th>
                            <th className="fw-bold">ORG</th>
                            <th className="fw-bold">DEST</th>
                            <th className="fw-bold">SHC</th>
                            <th className="fw-bold">COMM DESC</th>
                            <th className="fw-bold">MFT PCS</th>
                            <th className="fw-bold">MFT WT</th>
                            <th className="fw-bold">CH WT</th>
                            <th className="fw-bold">REMARKS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataSource.Transit.map(
                            (shipment: any, shipmentIdx: number) => (
                              <tr key={shipmentIdx}>
                                <td>{shipment.cart_uld}</td>
                                <td>{shipment.awbNo}</td>
                                <td>{shipment.org}</td>
                                <td>{shipment.dest}</td>
                                <td>{shipment.ssc}</td>
                                <td>{shipment.commDesc}</td>
                                <td>{shipment.mftPcs}</td>
                                <td>{shipment.mftWt}</td>
                                <td>{shipment.chWt}</td>
                                <td className={shipment.remark ? 'print-remark' :''}>{shipment.remark}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={6}>Total:</td>
                            <td>{totalMftPcsForTansit()}</td>
                            <td>{totalMftWtForTransit().toFixed(2)}</td>
                            <td>{totalChargeableWeightForTransit().toFixed(2)}</td>
                            <td />
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mt-5">
                <div className="col-12">Prepared by:</div>
                <div className="col-3">Staff No: {dataSource.staffId}</div>
                <div className="col-3">Staff Name: {dataSource.staffName}</div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 text-right">
                  <button
                    className="app-btn app-btn-primary"
                    onClick={() => window.print()}
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default InboundManifestInvoicePage;
