import { handleLogout, ObjToQueryString } from '../utils/Utils';

export const getRequest = (URL: string): Promise<any> => {
  return fetch(`${URL}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem(`${process.env.REACT_APP_NAME}_TOKEN`)}`
    },
  }).then(res => {
    if (res.status === 401) {
      handleLogout();
    }

    return res;
  })
    .catch(() => {
      console.log(`TypeError: failed to fetch ${URL}`);
    });
};

export const postRequest = (URL: string, payload?: any): Promise<any> => {
  return fetch(`${URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem(`${process.env.REACT_APP_NAME}_TOKEN`)}`
    },
    body: JSON.stringify(payload),
  }).then(res => {
    if (res.status === 401) {
      handleLogout();
    }

    return res;
  }).catch(() => {
    console.log(`TypeError: failed to fetch ${URL}`);
  });
};

export const putRequest = (URL: string, payload?: any): Promise<any> => {
  return fetch(`${URL}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem(`${process.env.REACT_APP_NAME}_TOKEN`)}`
    },
    body: JSON.stringify(payload),
  }).then(res => {
    if (res.status === 401) {
      handleLogout();
    }

    return res;
  }).catch(() => {
    console.log(`TypeError: failed to fetch ${URL}`);
  });
};

export const deleteRequest = (URL: string, queryParams?: any): Promise<any> => {
  const queryString = ObjToQueryString(queryParams);
  return fetch(`${URL}${queryString}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem(`${process.env.REACT_APP_NAME}_TOKEN`)}`
    },
  }).then(res => {
    if (res.status === 401) {
      handleLogout();
    }

    return res;
  }).catch(() => {
    console.log(`TypeError: failed to fetch ${URL}`);
  });
};

export const postRequestNoToken = (URL: string, payload?: any): Promise<any> => {
  return fetch(`${URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).then(res => {
    return res;
  }).catch(() => {
    console.log(`TypeError: failed to fetch ${URL}`);
  });
};

export const postFileUploadRequest = (URL: string, payload?: any): Promise<any> => {
  return fetch(`${URL}`, {
    method: 'POST',
    body: payload,
  }).then(res => {
    if (res.status === 401) {
      handleLogout();
    }

    return res;
  }).catch(() => {
    console.log(`TypeError: failed to fetch ${URL}`);
  });
};

export const HandlerResponse = (resp: any) => {
  if (!!resp && [200, 201, 204].includes(resp.status)) {
    const jsonPromise = resp.json();

    return jsonPromise.then((data: any) => {
      return data;
    }).catch((error: any) => {
      console.log('Successful request, Unexpected end of JSON input', error);
      return null;
    })
  }

  return null;
};

export const getRequestWithoutToken = (URL: string): Promise<any> => {
  return fetch(`${URL}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => {
    if (res.status === 401) {
      handleLogout();
    }

    return res;
  })
    .catch(() => {
      console.log(`TypeError: failed to fetch ${URL}`);
    });
};
