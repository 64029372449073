const Constants = {
  ITEMS_PER_PAGE: 10,
};

const COUNTRY_LIST = [{ id: "India", name: "India" }];
const REGION_LIST = [{ id: "Karnataka", name: "Karnataka" }];


const BRANCH_LIST = [
  {
    id:'Hyadrabad',
    name:'Hyadrabad'
  },{
    id:'Visakhapatnam',
    name:'Visakhapatnam'
  },{
    id:'Vijayawada',
    name:'Vijayawada'
  },{
    id:'Bengaluru',
    name:'Bengaluru'
  }
]

export { Constants,  COUNTRY_LIST, REGION_LIST,BRANCH_LIST };
