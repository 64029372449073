import React, { ReactElement, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { IUserProfile } from "../../interfaces/user.interface";

import { IFlight } from "../../interfaces/flight.interface";
import {
  getAllInboundFlightsAPI,
  getAllOutboundFlightsAPI,
} from "../../api-services/flight.api";
//import { getAllUserAPI } from "../../api-services/user.api";

const tabList = [
  // {
  //   id: "user",
  //   name: "User",
  // },
  {
    id: "flight",
    name: "Flight",
  },
];

const SettingScreen = (): ReactElement => {
  let navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string>(tabList[0].id);
  const [userList, setUserList] = useState<IUserProfile[]>([]);
  const [flightList, setFlightList] = useState<IFlight[]>([]);
  const [activeSubTab, setActiveSubTab] = useState<string>("Outbound");

  useEffect(() => {
    if (activeSubTab === "Outbound") {
      getAllOutboundFlights();
    } else {
      getAllInboundFlights();
    }
  }, [activeSubTab]);

  const getAllOutboundFlights = (): void => {
    getAllOutboundFlightsAPI().then((res: { payload: IFlight[] }) => {
      setFlightList(!!res ? res.payload : []);
    });
  };

  const getAllInboundFlights = (): void => {
    getAllInboundFlightsAPI().then((res: { payload: IFlight[] }) => {
      setFlightList(!!res ? res.payload : []);
    });
  };

  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-md-10">
            <div className="d-flex">
              {tabList.map((item) => (
                <div
                  key={item.id}
                  className={`tab ${
                    item.id === activeTab ? "active" : ""
                  } col-md-2`}
                  onClick={() => setActiveTab(item.id)}
                >
                  <p
                    className={`tab-text ${
                      item.id === activeTab ? "active" : ""
                    }`}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="col-md-12 text-right">
            {activeTab === "user" ? (
              <button
                className="app-btn app-btn-primary"
                onClick={() => navigate("/add-user")}
              >
                Add User
              </button>
            ) : (
              <button
                className="app-btn app-btn-primary"
                onClick={() => navigate("/add-flight")}
              >
                Add Flight
              </button>
            )}
          </div> */}
          {activeTab === "user" ? (
            <div className="col-md-12">
              <div className="table-responsive mt-3">
                <table className="mb-0 w-100">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>NAME</th>
                      <th>EMAIL</th>
                      <th>PHONE</th>
                      <th>BRANCH</th>
                      {/* <th className="text-center">ACTION</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {userList.map((data, dataIdx: number) => (
                      <tr key={dataIdx}>
                        <td>{dataIdx + 1}</td>
                        <td>{data.userName}</td>
                        <td>{data.email}</td>
                        <td>{data.phone}</td>
                        <td>{data.branch}</td>
                        {/* <td className="text-center">
                          <i
                            className="fa fa-pencil-alt text-danger cursor-pointer"
                            onClick={() => navigate(`/edit-user/${data.uuid}`)}
                          />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-md-12 mt-2">
              <div className="row mt-4 mb-3">
                <div className="col-md-8">
                  <div className="d-flex">
                    <div
                      className={`tab ${
                        activeSubTab === "Outbound" ? "active" : ""
                      } col-md-2 d-flext align-items-center`}
                      onClick={() => setActiveSubTab("Outbound")}
                    >
                      <p style={{ marginBottom: "2px" }}>Outbound</p>
                    </div>
                    <div
                      className={`tab ${
                        activeSubTab === "Inbound" ? "active" : ""
                      } col-md-2 d-flext align-items-center`}
                      onClick={() => setActiveSubTab("Inbound")}
                    >
                      <p style={{ marginBottom: "2px" }}>Inbound</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-right">
                  <button
                    className="app-btn app-btn-primary"
                    onClick={() => navigate(`/add-flight/${activeSubTab}`)}
                  >
                    Add Flight
                  </button>
                </div>
              </div>
              <div className="table-responsive mt-1 mb-3">
                <table className="mb-0 w-100">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>FLIGHT NO</th>
                      <th>STATION</th>
                      <th>FREQUENCY</th>
                      <th>{activeSubTab === "Inbound" ? "STA" : "STD"}</th>
                      <th>
                        {activeSubTab === "Inbound"
                          ? "ARRIVAL FROM"
                          : "DEPARTURE TO"}
                      </th>
                      <th>AIRCRAFT TYPE</th>
                      <th>AIRLINE</th>
                      <th>AWS CODE</th>
                      <th className="text-center">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {flightList.map((data: IFlight, index: number) => (
                      <tr key={index}>
                        <td>{data.id}</td>
                        <td>{data.flightNo}</td>
                        <td>{data.station}</td>
                        <td>{data.frequency}</td>
                        <td>
                          {activeSubTab === "Inbound"
                            ? data.arrivalTime
                            : data.departureTime}
                        </td>
                        <td>
                          {activeSubTab === "Inbound"
                            ? data.arrivalFrom
                            : data.departureTo}
                        </td>
                        <td>{data.aircraftType}</td>
                        <td>{data.airline}</td>
                        <td>{data.awsCode}</td>
                        <td className="text-center">
                          <i
                            className="fa fa-pencil-alt text-danger cursor-pointer"
                            onClick={() =>
                              navigate(`/update-flight/${activeSubTab}/${data.id}`)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingScreen;
