import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import "./login.css";

import BackgroundImage from "../../assets/images/background.jpg";

import { ILogin } from "../../interfaces/auth.interface";
import { loginAPI } from "../../api-services/auth.api";

import { createUserAPI } from "../../api-services/user.api";
import { error } from "console";
import { ILocation } from "../../interfaces/location.interface";
import { getLocationsAPI } from "../../api-services/location.api";

interface ISignupForm {
  userName: string;
  email: string;
  phone: string;
  branch: string;
  password: string;
  confirmPassword: string;
}

const signupFormInit: ISignupForm = {
  userName: "",
  email: "",
  phone: "",
  branch: "",
  password: "",
  confirmPassword: "",
};

const signupFormErrorInit = {
  userName: false,
  email: false,
  phone: false,
  branch: false,
  password: false,
  confirmPassword: false,
};

interface IFrom {
  email: string;
  password: string;
}

const formInit: IFrom = {
  email: "",
  password: "",
};

const formErrInit = {
  email: false,
  password: false,
};

const Login = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<IFrom>(formInit);
  const [formError, setFormError] = useState(formErrInit);
  const [showLogin, setShowLogin] = useState<boolean>(true);
  const [signupForm, setSignupForm] = useState<ISignupForm>(signupFormInit);
  const [signupFormError, setSignupFormError] = useState(signupFormErrorInit);
  const [locationList, setLocationList] = useState<ILocation[]>([]);

  const inputHandler = (e: any, type: string): void => {
    setForm({ ...form, [type]: e });
    setFormError({ ...formError, [type]: false });
  };

  const signupInputHandler = (e: any, type: string): void => {
    const updatedForm: any = { ...signupForm };
    const updatedFormError: any = { ...signupFormError };

    const validationRules: Record<string, () => boolean> = {
      userName: () => updatedForm.userName === "",
      email: () => updatedForm.email === "",
      branch: () => updatedForm.branch === "Select Branch",
      confirmPassword: () => updatedForm.password !== e,
      phone: () => e.length !== 10,
    };

    updatedFormError[type] = validationRules[type]
      ? validationRules[type]()
      : false;

    updatedForm[type] = e;

    setSignupFormError(updatedFormError);
    setSignupForm(updatedForm);
  };

  const formValidation = (e: any): void => {
    e.preventDefault();
    let formErr = { ...formError };
    if (form.email === "" || form.password === "") {
      if (form.email === "") {
        formErr.email = true;
      }

      if (form.password === "") {
        formErr.password = true;
      }
      setFormError(formErr);
    } else {
      setLoading(true);

      const payload: ILogin = {
        email: form.email,
        password: form.password,
      };

      loginAPI(payload).then((res) => {
        if (!!res) {
          sessionStorage.setItem(
            `${process.env.REACT_APP_NAME}_TOKEN`,
            res.payload.token
          );
          navigate("/outbound-awb");
          setLoading(false);
        }
      });
    }
  };

  const signUpFormValidation = (): boolean => {
    return (
      signupForm.userName !== "" &&
      signupForm.branch.length > 0 &&
      signupForm.email !== "" &&
      signupForm.phone.length === 10 &&
      signupForm.password !== ""
    );
    // Check if there are any errors; if so, the form is not valid
  };

  const onSingUpHandler = (e: any): void => {
    e.preventDefault();
    let formErr = { ...signupFormError };

    if (
      signupForm.userName === "" ||
      signupForm.phone === "" ||
      signupForm.email === "" ||
      signupForm.branch === "" ||
      signupForm.password === ""
    ) {
      if (signupForm.userName === "") {
        formErr.userName = true;
      }

      if (signupForm.phone === "" || signupForm.phone.length !== 10) {
        formErr.phone = true;
      }

      if (signupForm.email === "") {
        formErr.email = true;
      }
      if (signupForm.branch === "") {
        formErr.branch = true;
      }
      if (signupForm.password === "") {
        formErr.password = true;
      }
      setSignupFormError(formErr);
    } else {
      const payload = {
        userName: signupForm.userName,
        email: signupForm.email,
        phone: signupForm.phone,
        role: "USER",
        password: signupForm.password,
        branch: signupForm.branch,
      };

      createUserAPI(payload).then((res: any) => {
        if (res) {
          setShowLogin(!showLogin);
        }
      });
    }
  };

  const getLocation = () => {
    getLocationsAPI().then((res) => {
      if (res) {
        setLocationList(res.payload);
      }
    });
  };

  useEffect(() => {
    if (!showLogin) {
      getLocation();
    }
  }, [showLogin]);

  return (
    <div
      className="sign-in__wrapper"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="sign-in__backdrop" />
      {showLogin ? (
        <Form
          className="shadow p-4 bg-white rounded"
          onSubmit={(e) => formValidation(e)}
        >
          <img
            className="img-thumbnail mx-auto d-block mb-2"
            src={"http://cargo.sulopa.net/storage/app/public/37/cargo-logo.png"}
            alt="logo"
          />
          <div className="h4 mb-2 text-center mt-3">Sign In</div>

          <Form.Group className="mb-2" controlId="username">
            <Form.Label>email</Form.Label>
            <Form.Control
              type="text"
              value={form.email}
              placeholder="email"
              onChange={(e) => inputHandler(e.target.value, "email")}
            />
            {formError.email && (
              <p className="form-label-text text-danger">Please enter email</p>
            )}
          </Form.Group>
          <Form.Group className="mb-2" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={form.password}
              placeholder="Password"
              onChange={(e) => inputHandler(e.target.value, "password")}
            />
            {formError.password && (
              <p className="form-label-text text-danger">
                Please enter password
              </p>
            )}
          </Form.Group>

          <Form.Group className="mb-2" controlId="checkbox">
            <Form.Check type="checkbox" label="Remember me" />
          </Form.Group>
          {!loading ? (
            <Button className="w-100" variant="primary" type="submit">
              Log In
            </Button>
          ) : (
            <Button className="w-100" variant="primary" type="submit" disabled>
              Logging In...
            </Button>
          )}
          <div className="d-flex justify-content-between">
            <Button
              className="text-muted px-0"
              variant="link"
              onClick={() => setShowLogin(!showLogin)}
            >
              Sign Up
            </Button>
            {/* <Button className="text-muted px-0" variant="link">
              Forgot password?
            </Button> */}
          </div>
        </Form>
      ) : (
        <Form
          className="shadow p-4 bg-white rounded signup_form"
          onSubmit={(e) => onSingUpHandler(e)}
        >
          <img
            className="img-thumbnail mx-auto d-block mb-2"
            src={"http://cargo.sulopa.net/storage/app/public/37/cargo-logo.png"}
            alt="logo"
          />
          <div className="h4 mb-2 text-center mt-3">Sign Up</div>
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="mb-2" controlId="username">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  value={signupForm.userName}
                  placeholder="Enter User Name"
                  onChange={(e) =>
                    signupInputHandler(e.target.value, "userName")
                  }
                />
                {signupFormError.userName && (
                  <p className="form-label-text text-danger">
                    Please enter user name
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="branch">
                <Form.Label>Select Branch</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => signupInputHandler(e.target.value, "branch")}
                >
                  <option>Select Branch</option>
                  {locationList.map((value, ind) => (
                    <option key={ind} value={value.code}>
                      {value.region}
                    </option>
                  ))}
                </Form.Control>
                {signupFormError.branch && (
                  <p className="form-label-text text-danger">Select branch</p>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  value={signupForm.email}
                  placeholder="Enter Email"
                  onChange={(e) => signupInputHandler(e.target.value, "email")}
                />
                {signupFormError.email && (
                  <p className="form-label-text text-danger">
                    Please enter email
                  </p>
                )}
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="mb-2" controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  value={signupForm.phone}
                  placeholder="Enter Phone number"
                  onChange={(e) => signupInputHandler(e.target.value, "phone")}
                />
                {signupFormError.phone && (
                  <p className="form-label-text text-danger">
                    Please enter 10 digit number
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={signupForm.password}
                  placeholder="Enter Password"
                  onChange={(e) =>
                    signupInputHandler(e.target.value, "password")
                  }
                />
                {signupFormError.password && (
                  <p className="form-label-text text-danger">
                    Please enter password
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="confirmPassword">
                <Form.Label>Consfirm Password</Form.Label>
                <Form.Control
                  type="password"
                  value={signupForm.confirmPassword}
                  placeholder="Enter Confirm Password"
                  onChange={(e) =>
                    signupInputHandler(e.target.value, "confirmPassword")
                  }
                />
                {signupFormError.confirmPassword && (
                  <p className="form-label-text text-danger">
                    Please enter Confirm password
                  </p>
                )}
              </Form.Group>
            </div>
          </div>

          <Button className="w-100" variant="primary" type="submit">
            Sign Up
          </Button>
          <div className="d-flex justify-content-between">
            <span className="text-muted px-0">Already have account</span>
            <Button
              className="text-muted px-0"
              variant="link"
              onClick={() => setShowLogin(!showLogin)}
            >
              Sign In
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Login;
