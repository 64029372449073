import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

import styles from './InboundManifest.module.scss';

import { useAlertStore, useUserProfileStore } from '../../store/store';

import FlightListModal from '../../components/common/FlightListModal';

import {
  IForm,
  IFormErr, IInboundManifestInvoice,
  IInboundManifestPayload, ILocalTransitPayload,
} from '../../interfaces/inboundManifest.interface';
import { flightInit, IFlight } from '../../interfaces/flight.interface';
import { ISelectDropdown } from '../../interfaces/shipment.interface';
import {
  checkInboundAwbAPI,
  createInboundManifestAPI,
  getInboundManifestByIdAPI,
  updateInboundManifestAPI
} from '../../api-services/inboundManifest.api';
import { getAirportListAPI } from '../../api-services/airport.api';
import { IAirport } from '../../interfaces/airport.interface';
import SameAwbNoModal from '../../components/common/SameAwbNoModal';

const date = new Date();

const IrregularityList = [
  { label: 'MSCA', value: 'MSCA' },
  { label: 'FDCA', value: 'FDCA' },
  { label: 'Crushed', value: 'Crushed' },
  { label: 'Burst', value: 'Burst' },
  { label: 'Wet', value: 'Wet' },
  { label: 'Tape Torn', value: 'Tape Torn' },
  { label: 'Open', value: 'Open' },
  { label: 'Broken', value: 'Broken' },
  { label: 'Pallet Damages', value: 'Pallet Damages' },
  { label: 'Damage by Forklift', value: 'Damage by Forklift' },
  { label: 'Tilt Watch Set off', value: 'Tilt Watch Set off' },
];

const EditInboundManifestPage = (): ReactElement => {
  const navigate = useNavigate();
  const { id } = useParams();

  const formInitial: IForm = {
    date: [
      date.getFullYear(),
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1,
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate(),
    ].join('-'),
    selectedFlight: flightInit,
    owner: '',
    pointOfLoading: '',
    pointOfUnloading: '',
    tailNo: '',
    totalPcs: '',
    totalAwb: '',
    grossWt: '',
    localList: [{
      uuid: '',
      cart: 'Bulk',
      awbNo: '',
      org: [],
      dest: [],
      shc: '',
      commDesc: '',
      mftPcs: '',
      mftWt: '',
      chWt: '',
      receivedPcs: '',
    }],
    transitList: [{
      uuid: '',
      cart: 'Bulk',
      awbNo: '',
      org: [],
      dest: [],
      shc: '',
      commDesc: '',
      mftPcs: '',
      mftWt: '',
      chWt: '',
      receivedPcs: '',
    }],
    irregularityList: [{
      uuid: '',
      cart: 'Bulk',
      awbNo: '',
      irregularity: [],
      irregularityPcs: '',
      remarks: '',
    }],
    staffId: '',
    staffName: '',
  };
  const formErrorInit: IFormErr = {
    date: false,
    totalPcs: false,
    totalAwb: false,
    grossWt: false,
    localList: [
      {
        cart: false,
        awbNo: false,
        org: false,
        dest: false,
        shc: false,
        commDesc: false,
        shipper: false,
        consignee: false,
        mftPcs: false,
        mftWt: false,
        chWt: false,
      },
    ],
    transitList: [
      {
        cart: false,
        awbNo: false,
        org: false,
        dest: false,
        shc: false,
        commDesc: false,
        shipper: false,
        consignee: false,
        mftPcs: false,
        mftWt: false,
        chWt: false,
      },
    ],
    irregularityList: [
      {
        cart: false,
        awbNo: false,
        irregularity: false,
        irregularityPcs: false,
        remarks: false,
      },
    ],
    staffId: false,
    staffName: false,
  };

  const [form, setForm] = useState<IForm>(formInitial);
  const [formError, setFormError] = useState<IFormErr>(formErrorInit);
  const [isFlightListModalOpened, setIsFlightListModalOpened] = useState<boolean>(false);
  const [airports, setAirports] = useState<ISelectDropdown[]>([]);
  const [uuid, setUuid] = useState<string>(id || '');
  const [isSameAwbNoModalOpened, setIsSameAwbNoModalOpened] = useState<boolean>(false);
  const [gridInfo, setGridInfo] = useState<{type: string, idx: number}>({ type: '', idx: -1 });

  const userProfile = useUserProfileStore((state) => state.userProfile);
  const updateAlertText = useAlertStore(state => state.updateAlertText);

  useEffect(() => {
    getAirports();
  }, []);

  const getAirports = (): void => {
    getAirportListAPI()
      .then((res: {payload: IAirport[]}) => {
        setAirports(res.payload.map((airport) => ({ label: airport.airportCode, value: airport.airportCode })));
      });
  };

  useEffect(() => {
    if (!!uuid) {
      getInboundManifestById();
    }
  }, []);

  const getInboundManifestById = (): void => {
    getInboundManifestByIdAPI(uuid)
      .then((res: {payload: IInboundManifestInvoice}) => {
        setForm({
          ...form,
          date: [
            new Date(res.payload.date).getFullYear(),
            (new Date(res.payload.date).getMonth() + 1).toString().length > 1
              ? (new Date(res.payload.date).getMonth() + 1)
              : ('0' + (new Date(res.payload.date).getMonth() + 1)),
            new Date(res.payload.date).getDate().toString().length > 1
              ? new Date(res.payload.date).getDate()
              : ('0' + new Date(res.payload.date).getDate()),
          ].join('-'),
          selectedFlight: {
            ...form.selectedFlight,
            id: res.payload.flightDetails.id,
            flightNo: res.payload.flightDetails.flightNo,
            airline: res.payload.pointOfLoading,
            arrivalFrom: res.payload.pointOfUploading,
            awsCode: res.payload.flightDetails.awsCode,
          },
          tailNo: res.payload.tailNo,
          owner: res.payload.owner,
          pointOfLoading: res.payload.pointOfLoading,
          pointOfUnloading: res.payload.pointOfUploading,
          totalPcs: res.payload.totalPcs,
          totalAwb: res.payload.totalAwb,
          grossWt: res.payload.grossWt,
          localList: res.payload.Local.map((local) => ({
            uuid: local.uuid,
            cart: local.cart_uld,
            awbNo: local.awbNo.split('-')[1],
            org: [{ label: local.org, value: local.org }],
            dest: [{ label: local.dest, value: local.dest }],
            shc: local.ssc,
            commDesc: local.commDesc,
            mftPcs: local.mftPcs,
            mftWt: local.mftWt,
            chWt: local.chWt,
            receivedPcs: local.receivedPcs,
          })),
          transitList: res.payload.Transit.length > 0
            ? res.payload.Transit.map((transit) => ({
              uuid: transit.uuid,
              cart: transit.cart_uld,
              awbNo: transit.awbNo.split('-')[1],
              org: [{ label: transit.org, value: transit.org }],
              dest: [{ label: transit.dest, value: transit.dest }],
              shc: transit.ssc,
              commDesc: transit.commDesc,
              mftPcs: transit.mftPcs,
              mftWt: transit.mftWt,
              chWt: transit.chWt,
              receivedPcs: transit.receivedPcs,
            }))
            : formInitial.transitList.map((item) => ({
              ...item,
              org: [{ label: res.payload.pointOfLoading, value: res.payload.pointOfLoading }],
              dest: [{ label: res.payload.pointOfUploading, value: res.payload.pointOfUploading }]
            })),
          irregularityList: res.payload.Irregularity.length > 0
            ? res.payload.Irregularity.map((irregularity) => ({
              uuid: irregularity.uuid,
              cart: irregularity.cart_uld,
              awbNo: irregularity.awbNo.split('-')[1],
              irregularity: [{ label: irregularity.irregularity, value: irregularity.irregularity }],
              irregularityPcs: irregularity.irregularityPcs,
              remarks: irregularity.remarks,
            }))
            : formInitial.irregularityList,
          staffId: res.payload.staffId,
          staffName: res.payload.staffName,
        });
        setFormError({
          ...formError,
          localList: res.payload.Local.map(() => ({
            cart: false,
            awbNo: false,
            org: false,
            dest: false,
            shc: false,
            commDesc: false,
            shipper: false,
            consignee: false,
            mftPcs: false,
            mftWt: false,
            chWt: false,
            remark: false,
          })),
          transitList: res.payload.Transit.length > 0
            ? res.payload.Transit.map(() => ({
              cart: false,
              awbNo: false,
              org: false,
              dest: false,
              shc: false,
              commDesc: false,
              shipper: false,
              consignee: false,
              mftPcs: false,
              mftWt: false,
              chWt: false,
              remark: false,
            }))
            : formErrorInit.transitList
        });
      });
  };

  const checkInboundAwb = (awbNo: string, type: string, idx: number): void => {
    const params = { awbNo: `${form.selectedFlight.awsCode}-${awbNo}`, };

    checkInboundAwbAPI(params)
      .then((res: {message: string}) => {
        if (res.message === 'Successful') {
          setIsSameAwbNoModalOpened(true);
          setGridInfo({ type, idx });
        }
      });
  };

  const onUpdateForm = (value: any, type: string): void => {
    const updatedForm: any = { ...form };
    const updatedErrorForm: any = { ...formError };
    updatedForm[type] = value;

    if (type === 'selectedFlight') {
      updatedForm['localList'] = updatedForm['localList'].map((local: any) => ({
        ...local,
        org: [{ label: updatedForm.selectedFlight?.airportCode, value: updatedForm.selectedFlight?.airportCode }],
        dest: [{ label: userProfile.branch, value: userProfile.branch }],
      }));

      updatedForm['transitList'] = updatedForm['transitList'].map((transit: any) => ({
        ...transit,
        org: [{ label: updatedForm.selectedFlight?.airportCode, value: updatedForm.selectedFlight?.airportCode }],
        dest: [{ label: userProfile.branch, value: userProfile.branch }],
      }));

      updatedForm['owner'] = updatedForm.selectedFlight?.airline;
      updatedForm['pointOfLoading'] = updatedForm.selectedFlight?.airportCode;
      updatedForm['pointOfUnloading'] = userProfile?.branch;
    }

    setForm(updatedForm);
    setFormError(updatedErrorForm);
  };

  const searchFlightHandler = (): void => {
    setIsFlightListModalOpened(true);
    setForm({
      ...form,
      staffName: userProfile.userName,
    });
  };

  const resetHandler = (): void => {
    setForm(formInitial);
  };

  const addData = (type: 'local' | 'transit'): void => {
    if (type === 'local') {
      const items = [...form.localList];
      items.push(formInitial.localList[0]);
      setForm({
        ...form,
        localList: items.map((item) => ({
          ...item,
          org: [{ label: form.pointOfLoading, value: form.pointOfLoading }],
          dest: [{ label: form.pointOfUnloading, value: form.pointOfUnloading }]
        }))
      });

      const errItems = [...formError.localList];
      errItems.push(formErrorInit.localList[0]);
      setFormError({ ...formError, localList: errItems });
    } else {
      const items = [...form.transitList];
      items.push(formInitial.transitList[0]);
      setForm({
        ...form,
        transitList: items.map((item) => ({
          ...item,
          org: [{ label: form.pointOfLoading, value: form.pointOfLoading }],
          dest: [{ label: form.pointOfUnloading, value: form.pointOfUnloading }]
        }))
      });

      const errItems = [...formError.transitList];
      errItems.push(formErrorInit.transitList[0]);
      setFormError({ ...formError, transitList: errItems });
    }
  };

  const removeData = (type: 'local' | 'transit', idx: number): void => {
    if (type === 'local') {
      const items = [...form.localList];
      items.splice(idx, 1);
      setForm({ ...form, localList: items });
    } else {
      const items = [...form.transitList];
      items.splice(idx, 1);
      setForm({ ...form, transitList: items });
    }
  };

  const addIrregularity = (): void => {
    const items = [...form.irregularityList];
    items.push(formInitial.irregularityList[0]);
    setForm({
      ...form,
      irregularityList: items,
    });

    const errItems = [...formError.irregularityList];
    errItems.push(formErrorInit.irregularityList[0]);
    setFormError({ ...formError, irregularityList: errItems });
  };

  const removeIrregularity = (idx: number): void => {
    const items = [...form.irregularityList];
    items.splice(idx, 1);
    setForm({ ...form, irregularityList: items });
  };

  const getTotalCount = (type: 'local' | 'transit', measure: 'mftPcs' | 'mftWt' | 'chWt'): number => {
    if (type === 'local') {
      return form.localList.reduce((acc, value) => {
        if (measure === 'mftPcs') {
          acc += !!value.mftPcs ? parseInt(value.mftPcs) : 0;
        } else if (measure === 'mftWt') {
          acc += !!value.mftWt ? parseInt(value.mftWt) : 0;
        } else if (measure === 'chWt') {
          acc += !!value.chWt ? parseInt(value.chWt) : 0;
        }
        return acc;
      }, 0);
    } else {
      return form.transitList.reduce((acc, value) => {
        if (measure === 'mftPcs') {
          acc += !!value.mftPcs ? parseInt(value.mftPcs) : 0;
        } else if (measure === 'mftWt') {
          acc += !!value.mftWt ? parseInt(value.mftWt) : 0;
        } else if (measure === 'chWt') {
          acc += !!value.chWt ? parseInt(value.chWt) : 0;
        }
        return acc;
      }, 0);
    }
  };

  const formValidity = (): boolean => {
    return (
      form.date !== '' &&
      form.selectedFlight.flightNo !== '' &&
      form.totalPcs !== '' &&
      form.totalAwb !== '' &&
      form.grossWt !== '' &&
      form.localList.reduce((acc, value) => acc && parseInt(value.mftPcs) > 0, true) &&
      form.staffId !== '' &&
      form.staffName !== ''
    );
  };

  const isValidTransit = (): boolean => {
    return form.transitList.filter(transit =>
      !!transit.cart &&
      !!transit.awbNo &&
      !!transit.shc &&
      !!transit.commDesc &&
      !!transit.mftPcs &&
      !!transit.mftWt &&
      !!transit.chWt).length > 0;
  };

  const isValidIrregularity = (): boolean => {
    return form.irregularityList.filter(transit =>
      !!transit.cart &&
      !!transit.awbNo &&
      !!transit.irregularity &&
      !!transit.irregularityPcs).length > 0;
  };

  const onSave = (): void => {
    const payload: IInboundManifestPayload = {
      date: form.date,
      flightNo: form.selectedFlight.id,
      tailNo: form.tailNo || '',
      owner: form.owner,
      pointOfLoading: form.pointOfLoading,
      pointOfUploading: form.pointOfUnloading,
      totalPcs: form.totalPcs,
      totalAwb: form.totalAwb,
      grossWt: form.grossWt,
      staffId: form.staffId,
      staffName: form.staffName,
      inboundLocal: form.localList.map(data => ({
        uuid: data.uuid,
        cart_uld: data.cart,
        awbNo: `${form.selectedFlight.awsCode}-${data.awbNo}`,
        org: data.org[0].value,
        dest: data.dest[0].value,
        ssc: data.shc,
        commDesc: data.commDesc,
        mftPcs: data.mftPcs,
        mftWt: data.mftWt,
        chWt: data.chWt,
        receivedPcs: data.receivedPcs,
      })),
      inboundTransit: isValidTransit()
        ? form.transitList.map(data => ({
          uuid: data.uuid,
          cart_uld: data.cart,
          awbNo: `${form.selectedFlight.awsCode}-${data.awbNo}`,
          org: data.org[0].value,
          dest: data.dest[0].value,
          ssc: data.shc,
          commDesc: data.commDesc,
          mftPcs: data.mftPcs,
          mftWt: data.mftWt,
          chWt: data.chWt,
          receivedPcs: data.receivedPcs,
        }))
        : [],
      inboundIrregularity: isValidIrregularity()
        ? form.irregularityList.map(data => ({
          uuid: data.uuid,
          cart_uld: data.cart,
          awbNo: `${form.selectedFlight.awsCode}-${data.awbNo}`,
          irregularity: data.irregularity[0].value,
          irregularityPcs: data.irregularityPcs,
          remarks: data.remarks,
        }))
        : []
    };

    const cleanUUIDs = (items: any[]) => items.map(item => {
      if (!item.uuid) delete item.uuid;
      return item;
    });

    payload.inboundLocal = cleanUUIDs(payload.inboundLocal);
    payload.inboundTransit = cleanUUIDs(payload.inboundTransit);
    payload.inboundIrregularity = cleanUUIDs(payload.inboundIrregularity);

    // debugger;

    (!!uuid ? updateInboundManifestAPI(uuid, payload) : createInboundManifestAPI(payload))
      .then((res: {message: string, payload: {inboundManifestUuid: string}}) => {
        if (!!res) {
          updateAlertText(res.message, 'success');
          setUuid(res.payload.inboundManifestUuid);
        } else {
          updateAlertText('Something went wrong !!!', 'danger');
        }
      });
  };

  return (
    <div className="content">
      <div className="card px-5 py-3">

        <div className="row">
          <div className="col-2">
            <label id="shipping-date" className="form-label-text d-block mb-1">Date</label>
            <input
              id="shipping-date"
              type="date"
              className="form-input-field w-100"
              disabled={!!uuid}
              onChange={(e) => onUpdateForm(e.target.value, 'date')}
              value={form.date}
            />
          </div>

          {
            !uuid &&
            <div className="col-8 mt-4">
              <button
                className="app-btn app-btn-primary-inverted me-2"
                onClick={() => resetHandler()}
              >
                Reset
              </button>
              <button
                className="app-btn app-btn-primary"
                onClick={() => searchFlightHandler()}
              >
                Search
              </button>
            </div>
          }
        </div>

        <hr/>

        {
          form.selectedFlight.flightNo !== '' &&
          <Fragment>
            <div className="row">
              <div className="col-md-2 mb-2">
                <label
                  htmlFor="flight-number"
                  className="form-label-text d-block mb-1"
                >
                  Flight Number <span className="text-danger">*</span>
                </label>
                <input
                  id="flight-number"
                  type="text"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.selectedFlight.flightNo}
                />
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="date"
                  className="form-label-text d-block mb-1"
                >
                  Date <span className="text-danger">*</span>
                </label>
                <input
                  id="date"
                  type="date"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.date}
                />
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="tail-no"
                  className="form-label-text d-block mb-1"
                >
                  Tail No
                </label>
                <input
                  id="tail-no"
                  type="text"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'tailNo')}
                  value={form.tailNo}
                />
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="owner"
                  className="form-label-text d-block mb-1"
                >
                  Owner / Operator <span className="text-danger">*</span>
                </label>
                <input
                  id="owner"
                  type="text"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.owner}
                />
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="point-of-loading"
                  className="form-label-text d-block mb-1"
                >
                  Point of Loading <span className="text-danger">*</span>
                </label>
                <input
                  id="point-of-loading"
                  type="text"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.pointOfLoading}
                />
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="point-of-uploading"
                  className="form-label-text d-block mb-1"
                >
                  Point of Unloading <span className="text-danger">*</span>
                </label>
                <input
                  id="point-of-uploading"
                  type="text"
                  className="form-input-field w-100"
                  disabled={true}
                  onChange={() => null}
                  value={form.pointOfUnloading}
                />
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="total-pcs"
                  className="form-label-text d-block mb-1"
                >
                  Total Pcs <span className="text-danger">*</span>
                </label>
                <input
                  id="total-pcs"
                  type="number"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'totalPcs')}
                  value={form.totalPcs}
                />
                {
                  formError.totalPcs &&
                  <p className="form-label-text text-danger">
                    Please enter total pcs, shouldn't less than or equal to 0
                  </p>
                }
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="total-awb"
                  className="form-label-text d-block mb-1"
                >
                  Total AWB <span className="text-danger">*</span>
                </label>
                <input
                  id="total-awb"
                  type="number"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'totalAwb')}
                  value={form.totalAwb}
                />
                {
                  formError.totalAwb &&
                  <p className="form-label-text text-danger">
                    Please enter total AWB, shouldn't less than or equal to 0
                  </p>
                }
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="gross-wt"
                  className="form-label-text d-block mb-1"
                >
                  Gross Wt <span className="text-danger">*</span>
                </label>
                <input
                  id="gross-wt"
                  type="number"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'grossWt')}
                  value={form.grossWt}
                />
                {
                  formError.grossWt &&
                  <p className="form-label-text text-danger">
                    Please enter gross weight, shouldn't less than or equal to 0
                  </p>
                }
              </div>
            </div>

            <hr/>

            {
              form.localList.length > 0 &&
              <Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-title mb-3">Local</div>
                    <div className="table-responsive">
                      <table className="mb-0 w-100">
                        <thead>
                        <tr>
                          <th>CART / ULD</th>
                          <th>AWB No</th>
                          <th>ORG</th>
                          <th>DEST</th>
                          <th>SHC</th>
                          <th>COMM DESC</th>
                          <th>MFT PCS</th>
                          <th>MFT WT (KG)</th>
                          <th>CH WT (KG)</th>
                          <th>RECEIVED PCS</th>
                          <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          form.localList.map((data, dataIdx: number) => (
                            <tr key={dataIdx}>
                              <td>
                                <input
                                  type="text"
                                  name={data.cart}
                                  className="form-input-field w-100"
                                  disabled={true}
                                  onChange={() => null}
                                  value={data.cart}
                                />
                              </td>
                              <td className="position-relative">
                                <span className={styles['prefix-label']}>{form.selectedFlight.awsCode} - </span>
                                <input
                                  type="number"
                                  name={data.awbNo}
                                  className="form-input-field w-100"
                                  style={{ paddingLeft: '42px' }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.localList[dataIdx].awbNo = value;
                                    setForm({ ...form, localList: form.localList });

                                    formError.localList[dataIdx].awbNo = value.length !== 8;
                                    setFormError({ ...formError, localList: formError.localList });
                                  }}
                                  onBlur={(e) => {
                                    if (!!e.target.value) {
                                      checkInboundAwb(e.target.value, 'LOCAL', dataIdx);
                                    }
                                  }}
                                  value={form.localList[dataIdx].awbNo}
                                />
                                {
                                  formError.localList[dataIdx].awbNo &&
                                  <p className="form-label-text text-danger">Please enter AWB No</p>
                                }
                              </td>
                              <td>
                                <Select
                                  options={airports}
                                  isSearchable
                                  placeholder="Select airport"
                                  onChange={(e: any) => {
                                    form.localList[dataIdx].org = [e];
                                    setForm({ ...form, localList: form.localList });
                                  }}
                                  value={form.localList[dataIdx].org}
                                />
                                {
                                  formError.localList[dataIdx].org &&
                                  <p className="form-label-text text-danger">Please enter origin</p>
                                }
                              </td>
                              <td>
                                <Select
                                  options={airports}
                                  isSearchable
                                  placeholder="Select airport"
                                  onChange={(e: any) => {
                                    form.localList[dataIdx].dest = [e];
                                    setForm({ ...form, localList: form.localList });
                                  }}
                                  value={form.localList[dataIdx].dest}
                                />
                                {
                                  formError.localList[dataIdx].dest &&
                                  <p className="form-label-text text-danger">Please enter destination</p>
                                }
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name={data.shc}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.localList[dataIdx].shc = value;
                                    setForm({ ...form, localList: form.localList });
                                  }}
                                  value={data.shc}
                                />
                                {
                                  formError.localList[dataIdx].shc &&
                                  <p className="form-label-text text-danger">Please enter SHC</p>
                                }
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name={data.commDesc}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.localList[dataIdx].commDesc = value;
                                    setForm({ ...form, localList: form.localList });
                                  }}
                                  value={data.commDesc}
                                />
                                {
                                  formError.localList[dataIdx].commDesc &&
                                  <p className="form-label-text text-danger">Please enter Comm Desc</p>
                                }
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name={data.mftPcs}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.localList[dataIdx].mftPcs = value;
                                    setForm({ ...form, localList: form.localList });
                                  }}
                                  value={data.mftPcs}
                                />
                                {
                                  formError.localList[dataIdx].mftPcs &&
                                  <p className="form-label-text text-danger">
                                    Please enter MFT pcs, shouldn't less than or equal to 0
                                  </p>
                                }
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name={data.mftWt}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.localList[dataIdx].mftWt = value;
                                    setForm({ ...form, localList: form.localList });
                                  }}
                                  value={data.mftWt}
                                />
                                {
                                  formError.localList[dataIdx].mftWt &&
                                  <p className="form-label-text text-danger">
                                    Please enter MFT weight, shouldn't less than or equal to 0
                                  </p>
                                }
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name={data.chWt}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.localList[dataIdx].chWt = value;
                                    setForm({ ...form, localList: form.localList });
                                  }}
                                  value={data.chWt}
                                />
                                {
                                  formError.localList[dataIdx].chWt &&
                                  <p className="form-label-text text-danger">
                                    Please enter chargeable weight, shouldn't less than or equal to 0
                                  </p>
                                }
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.localList[dataIdx].receivedPcs = value;
                                    setForm({ ...form, localList: form.localList });
                                  }}
                                  value={data.receivedPcs}
                                />
                              </td>
                              <td className="text-center" style={{ width: '50px' }}>
                                {
                                  (form.localList.length === 1 ||
                                    form.localList.length - 1 === dataIdx) && (
                                    <i
                                      className={`fa fa-plus text-primary cursor-pointer
                                  ${form.localList.length > 1 && 'me-2'}`}
                                      onClick={() => addData('local')}
                                    />
                                  )
                                }
                                {
                                  form.localList.length !== 1 && (
                                    <i
                                      className="fa fa-trash text-danger cursor-pointer"
                                      onClick={() => removeData('local', dataIdx)}
                                    />
                                  )
                                }
                              </td>
                            </tr>
                          ))
                        }
                        <tr>
                          <td>Summary:</td>
                          <td/>
                          <td/>
                          <td/>
                          <td/>
                          <td>Total:</td>
                          <td>{getTotalCount('local', 'mftPcs')}</td>
                          <td>{getTotalCount('local', 'mftWt')}</td>
                          <td>{getTotalCount('local', 'chWt')}</td>
                          <td/>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr/>
              </Fragment>
            }

            {
              form.transitList.length > 0 &&
              <Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-title mb-3">Transit</div>
                    <div className="table-responsive">
                      <table className="mb-0 w-100">
                        <thead>
                        <tr>
                          <th>CART / ULD</th>
                          <th>AWB No</th>
                          <th>ORG</th>
                          <th>DEST</th>
                          <th>SHC</th>
                          <th>COMM DESC</th>
                          <th>MFT PCS</th>
                          <th>MFT WT (KG)</th>
                          <th>CH WT (KG)</th>
                          <th>RECEIVED PCS</th>
                          <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          form.transitList.map((data, dataIdx: number) => (
                            <tr key={dataIdx}>
                              <td>
                                <input
                                  type="text"
                                  name={data.cart}
                                  className="form-input-field w-100"
                                  disabled={true}
                                  onChange={() => null}
                                  value={data.cart}
                                />
                              </td>
                              <td className="position-relative">
                                <span className={styles['prefix-label']}>{form.selectedFlight.awsCode} - </span>
                                <input
                                  type="number"
                                  name={data.awbNo}
                                  className="form-input-field w-100"
                                  style={{ paddingLeft: '42px' }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.transitList[dataIdx].awbNo = value;
                                    setForm({ ...form, transitList: form.transitList });

                                    formError.transitList[dataIdx].awbNo = value.length !== 8;
                                    setFormError({ ...formError, transitList: formError.transitList });
                                  }}
                                  onBlur={(e) => {
                                    if (!!e.target.value) {
                                      checkInboundAwb(e.target.value, 'TRANSIT', dataIdx);
                                    }
                                  }}
                                  value={form.transitList[dataIdx].awbNo}
                                />
                                {
                                  formError.transitList[dataIdx].awbNo &&
                                  <p className="form-label-text text-danger">Please enter AWB No</p>
                                }
                              </td>
                              <td>
                                <Select
                                  options={airports}
                                  isSearchable
                                  placeholder="Select airport"
                                  onChange={(e: any) => {
                                    form.transitList[dataIdx].org = [e];
                                    setForm({ ...form, transitList: form.transitList });
                                  }}
                                  value={form.transitList[dataIdx].org}
                                />
                                {
                                  formError.transitList[dataIdx].org &&
                                  <p className="form-label-text text-danger">Please enter origin</p>
                                }
                              </td>
                              <td>
                                <Select
                                  options={airports}
                                  isSearchable
                                  placeholder="Select airport"
                                  onChange={(e: any) => {
                                    form.transitList[dataIdx].dest = [e];
                                    setForm({ ...form, transitList: form.transitList });
                                  }}
                                  value={form.transitList[dataIdx].dest}
                                />
                                {
                                  formError.transitList[dataIdx].dest &&
                                  <p className="form-label-text text-danger">Please enter destination</p>
                                }
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name={data.shc}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.transitList[dataIdx].shc = value;
                                    setForm({ ...form, transitList: form.transitList });
                                  }}
                                  value={data.shc}
                                />
                                {
                                  formError.transitList[dataIdx].shc &&
                                  <p className="form-label-text text-danger">Please enter SHC</p>
                                }
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name={data.commDesc}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.transitList[dataIdx].commDesc = value;
                                    setForm({ ...form, transitList: form.transitList });
                                  }}
                                  value={data.commDesc}
                                />
                                {
                                  formError.transitList[dataIdx].commDesc &&
                                  <p className="form-label-text text-danger">Please enter Comm Desc</p>
                                }
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name={data.mftPcs}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.transitList[dataIdx].mftPcs = value;
                                    setForm({ ...form, transitList: form.transitList });
                                  }}
                                  value={data.mftPcs}
                                />
                                {
                                  formError.transitList[dataIdx].mftPcs &&
                                  <p className="form-label-text text-danger">
                                    Please enter MFT pcs, shouldn't less than or equal to 0
                                  </p>
                                }
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name={data.mftWt}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.transitList[dataIdx].mftWt = value;
                                    setForm({ ...form, transitList: form.transitList });
                                  }}
                                  value={data.mftWt}
                                />
                                {
                                  formError.transitList[dataIdx].mftWt &&
                                  <p className="form-label-text text-danger">
                                    Please enter MFT weight, shouldn't less than or equal to 0
                                  </p>
                                }
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name={data.chWt}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.transitList[dataIdx].chWt = value;
                                    setForm({ ...form, transitList: form.transitList });
                                  }}
                                  value={data.chWt}
                                />
                                {
                                  formError.transitList[dataIdx].chWt &&
                                  <p className="form-label-text text-danger">
                                    Please enter chargeable weight, shouldn't less than or equal to 0
                                  </p>
                                }
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.transitList[dataIdx].receivedPcs = value;
                                    setForm({ ...form, transitList: form.transitList });
                                  }}
                                  value={data.receivedPcs}
                                />
                              </td>
                              <td className="text-center" style={{ width: '50px' }}>
                                {
                                  (form.transitList.length === 1 ||
                                    form.transitList.length - 1 === dataIdx) &&
                                  <i
                                    className={`fa fa-plus text-primary cursor-pointer
                              ${form.transitList.length > 1 && 'me-2'}`}
                                    onClick={() => addData('transit')}
                                  />
                                }
                                {
                                  form.transitList.length !== 1 &&
                                  <i
                                    className="fa fa-trash text-danger cursor-pointer"
                                    onClick={() => removeData('transit', dataIdx)}
                                  />
                                }
                              </td>
                            </tr>
                          ))
                        }
                        <tr>
                          <td>Summary:</td>
                          <td/>
                          <td/>
                          <td/>
                          <td/>
                          <td>Total:</td>
                          <td>{getTotalCount('transit', 'mftPcs')}</td>
                          <td>{getTotalCount('transit', 'mftWt')}</td>
                          <td>{getTotalCount('transit', 'chWt')}</td>
                          <td/>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr/>
              </Fragment>
            }

            {
              form.irregularityList.length > 0 &&
              <Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-title mb-3">Irregularities</div>
                    <div className="table-responsive">
                      <table className="mb-0 w-100">
                        <thead>
                        <tr>
                          <th>CART / ULD</th>
                          <th>AWB No</th>
                          <th>Irregularity</th>
                          <th>Irregularity Pcs</th>
                          <th>Remarks</th>
                          <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          form.irregularityList.map((data, dataIdx: number) => (
                            <tr key={dataIdx}>
                              <td>
                                <input
                                  type="text"
                                  name={data.cart}
                                  className="form-input-field w-100"
                                  disabled={true}
                                  onChange={() => null}
                                  value={data.cart}
                                />
                              </td>
                              <td className="position-relative">
                                <span className={styles['prefix-label']}>{form.selectedFlight.awsCode} - </span>
                                <input
                                  type="number"
                                  name={data.awbNo}
                                  className="form-input-field w-100"
                                  style={{ paddingLeft: '42px' }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.irregularityList[dataIdx].awbNo = value;
                                    setForm({ ...form, irregularityList: form.irregularityList });

                                    formError.irregularityList[dataIdx].awbNo = value.length !== 8;
                                    setFormError({ ...formError, irregularityList: formError.irregularityList });
                                  }}
                                  value={form.irregularityList[dataIdx].awbNo}
                                />
                                {
                                  formError.irregularityList[dataIdx].awbNo &&
                                  <p className="form-label-text text-danger">Please enter AWB No</p>
                                }
                              </td>
                              <td>
                                <Select
                                  options={IrregularityList}
                                  isSearchable
                                  placeholder="Select irregularity"
                                  onChange={(e: any) => {
                                    form.irregularityList[dataIdx].irregularity = [e];
                                    setForm({ ...form, irregularityList: form.irregularityList });
                                  }}
                                  value={form.irregularityList[dataIdx].irregularity}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name={data.irregularityPcs}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.irregularityList[dataIdx].irregularityPcs = value;
                                    setForm({ ...form, irregularityList: form.irregularityList });
                                  }}
                                  value={data.irregularityPcs}
                                />
                                {
                                  formError.irregularityList[dataIdx].irregularityPcs &&
                                  <p className="form-label-text text-danger">
                                    Please enter irregular pcs, shouldn't less than or equal to 0
                                  </p>
                                }
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name={data.remarks}
                                  className="form-input-field w-100"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    form.irregularityList[dataIdx].remarks = value;
                                    setForm({ ...form, irregularityList: form.irregularityList });
                                  }}
                                  value={data.remarks}
                                />
                              </td>
                              <td className="text-center" style={{ width: '50px' }}>
                                {
                                  (form.localList.length === 1 ||
                                    form.localList.length - 1 === dataIdx) && (
                                    <i
                                      className={`fa fa-plus text-primary cursor-pointer
                                  ${form.localList.length > 1 && 'me-2'}`}
                                      onClick={() => addIrregularity()}
                                    />
                                  )
                                }
                                {
                                  form.localList.length !== 1 && (
                                    <i
                                      className="fa fa-trash text-danger cursor-pointer"
                                      onClick={() => removeIrregularity(dataIdx)}
                                    />
                                  )
                                }
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr/>
              </Fragment>
            }

            <div className="card-title mb-3">Prepared By</div>
            <div className="row">
              <div className="col-md-2 mb-2">
                <label
                  htmlFor="staff-id"
                  className="form-label-text d-block mb-1"
                >
                  Staff ID <span className="text-danger">*</span>
                </label>
                <input
                  id="staff-id"
                  type="text"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'staffId')}
                  value={form.staffId}
                />
                {
                  formError.staffId &&
                  <p className="form-label-text text-danger">Please enter staff ID</p>
                }
              </div>

              <div className="col-md-2 mb-2">
                <label
                  htmlFor="staff-name"
                  className="form-label-text d-block mb-1"
                >
                  Staff Name <span className="text-danger">*</span>
                </label>
                <input
                  id="staff-name"
                  type="text"
                  className="form-input-field w-100"
                  onChange={(e) => onUpdateForm(e.target.value, 'staffName')}
                  value={form.staffName}
                />
                {
                  formError.staffName &&
                  <p className="form-label-text text-danger">Please enter staff name</p>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-right">
                <button
                  className="app-btn app-btn-primary me-2"
                  disabled={!formValidity()}
                  onClick={() => onSave()}
                >
                  Save
                </button>
                <button
                  className="app-btn app-btn-primary"
                  disabled={!uuid}
                  onClick={() => navigate(`/inbound-manifest-invoice/${uuid}`)}
                >
                  Invoice
                </button>
              </div>
            </div>
          </Fragment>
        }

      </div>

      {
        isFlightListModalOpened && (
          <FlightListModal
            isOpen={isFlightListModalOpened}
            setIsOpen={setIsFlightListModalOpened}
            onSelectFlight={(flightNo: IFlight) => onUpdateForm(flightNo, 'selectedFlight')}
            branch={userProfile.branch}
            type={'inbound'}
          />
        )
      }

      {
        isSameAwbNoModalOpened && (
          <SameAwbNoModal
            isOpen={isSameAwbNoModalOpened}
            setIsOpen={(isOpen, isResetRequired) => {
              if (isResetRequired) {
                if (gridInfo.type === 'LOCAL') {
                  form.localList[gridInfo.idx].awbNo = '';
                  setForm({ ...form, localList: form.localList });
                } else {
                  form.transitList[gridInfo.idx].awbNo = '';
                  setForm({ ...form, transitList: form.transitList });
                }
              }

              setIsSameAwbNoModalOpened(isOpen);
              setGridInfo({ type: '', idx: -1 });
            }}
          />
        )
      }
    </div>
  );
};

export default EditInboundManifestPage;
