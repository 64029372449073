import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';

import styles from './InboundCustomerInvoice.module.scss';

import { validateGst, validatePan } from '../../utils/Utils';

import { useAlertStore } from '../../store/store';

import { IForm, IFormErr, IPayload } from '../../interfaces/inboundCustomerInvoice.interface';
import { IInboundAwb } from '../../interfaces/inboundAwb.interface';
import { ICustomer } from '../../interfaces/customer.interface';
import { getInboundManifestByAwbNoAPI } from '../../api-services/inboundAWB.api';
import { getCustomersAPI } from '../../api-services/customer.api';
import {
  createInboundCustomerInvoiceAPI,
  getInboundCustomerInvoiceByIdAPI, updateInboundCustomerInvoiceAPI
} from '../../api-services/inboundCustomerInvoice';
import { ISelectDropdown } from '../../interfaces/shipment.interface';

const itemListForServiceProvided = [
  { name: 'TSP - General', ratePerKg: 4.5, minPerAwb: 100, },
  { name: 'TSP - Special', ratePerKg: 6.75, minPerAwb: 150, },
  { name: 'Demurrage - General (Per day)', ratePerKg: 4.5, minPerAwb: 100, },
  { name: 'Demurrage - Special (Per day)', ratePerKg: 6.75, minPerAwb: 150, },
  { name: 'Opening/repacking charges (Per Piece)', ratePerKg: 25, minPerAwb: 50, },
  { name: 'Strapping charges (Per Piece)', ratePerKg: 25, minPerAwb: 50, },
  { name: 'Amendment charges', ratePerKg: 0, minPerAwb: 100, },
  { name: 'Return cargo charges', ratePerKg: 0, minPerAwb: 100, },
  { name: 'Penalty for misdeclaration of weight', ratePerKg: 0, minPerAwb: 0, },
  { name: 'Penalty charges of damage of property', ratePerKg: 0, minPerAwb: 0, },
  { name: 'Other Penalty', ratePerKg: 0, minPerAwb: 0, },
];

const options: any[] =
  itemListForServiceProvided.map((item) => ({ value: item.name, label: item.name }));

const EditInboundCustomerInvoicePage = (): ReactElement => {
  const navigate = useNavigate();
  const { id } = useParams();

  const formInitial: IForm = {
    date: '',
    awbNo: '',
    flightNo: '',
    description: '',
    totalNoOfPackages: '',
    grossWeight: '',
    chargeableWeight: '',
    agentCode: '',
    agentName: '',
    gstNo: '',
    panNo: '',
    consigneeName: [],
    serviceProvidedList: [{
      item: [],
      hsnCode: '996719',
      rate: '',
      quantity: '',
      amount: '',
      igstPct: '',
      igstAmt: '',
      cgstPct: '',
      cgstAmt: '',
      sgstPct: '',
      sgstAmt: '',
      totalTax: '',
    }],
    netAmount: '',
  };
  const formErrorInit: IFormErr = {
    awbNo: false,
    flightNo: false,
    description: false,
    totalNoOfPackages: false,
    grossWeight: false,
    chargeableWeight: false,
    agentCode: false,
    agentName: false,
    gstNo: false,
    panNo: false,
    consigneeName: false,
    serviceProvidedList: [{
      item: false,
      hsnCode: false,
      rate: false,
      quantity: false,
      amount: false,
      igstPct: false,
      igstAmt: false,
      cgstPct: false,
      cgstAmt: false,
      sgstPct: false,
      sgstAmt: false,
      totalTax: false,
    }],
  };

  const [form, setForm] = useState<IForm>(formInitial);
  const [formError, setFormError] = useState<IFormErr>(formErrorInit);
  const [customers, setCustomers] = useState<ISelectDropdown[]>([]);
  const [uuid, setUuid] = useState<string>(id || '');

  const updateAlertText = useAlertStore(state => state.updateAlertText);

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (!!uuid) {
      getInboundCustomerInvoiceById();
    }
  }, []);

  const getInboundManifestByAwbNo = (): void => {
    const params = {
      awbNo: form.awbNo,
    };

    getInboundManifestByAwbNoAPI(params)
      .then((res: {payload: IInboundAwb[]}) => {
        if (!!res && !!res.payload) {
          formInit(res.payload[0]);
        } else {
          updateAlertText('No data found for the entered awb no', 'danger');
        }
      });
  };

  const getInboundCustomerInvoiceById = (): void => {
    getInboundCustomerInvoiceByIdAPI(uuid)
      .then((res: {payload: any}) => {
        setForm({
          ...form,
          date: res.payload.date,
          awbNo: res.payload.awbNo,
          flightNo: res.payload.flightDetails.id,
          description: res.payload.description,
          totalNoOfPackages: res.payload.totalNoOfPackages,
          grossWeight: res.payload.grossWeight,
          chargeableWeight: res.payload.chargeableWeight,
          agentCode: res.payload.agentCode,
          agentName: res.payload.agentName,
          gstNo: res.payload.gstNo,
          panNo: res.payload.panNo,
          consigneeName: [{ label: res.payload.consigneeDetails.name, value: res.payload.consigneeDetails.uuid }],
          serviceProvidedList: res.payload.serviceProvidedList.map((service: any) => ({
            uuid: service.uuid,
            item: [{ label: service.item, value: service.item }],
            hsnCode: service.hsnCode,
            rate: service.rate,
            quantity: service.quantity,
            amount: service.amount,
            igstPct: service.igstPct,
            igstAmt: service.igstAmt,
            cgstPct: service.cgstPct,
            cgstAmt: service.cgstAmt,
            sgstPct: service.sgstPct,
            sgstAmt: service.sgstAmt,
            totalTax: service.totalTax,
          })),
          netAmount: res.payload.netAmount,
        });
        setFormError({
          ...formError,
          serviceProvidedList: res.payload.serviceProvidedList.map(() => ({
            item: false,
            hsnCode: false,
            rate: false,
            quantity: false,
            amount: false,
            igstPct: false,
            igstAmt: false,
            cgstPct: false,
            cgstAmt: false,
            sgstPct: false,
            sgstAmt: false,
            totalTax: false,
          }))
        })
      });
  };

  const getCustomers = (): void => {
    getCustomersAPI()
      .then((res: {payload: ICustomer[]}) => {
        setCustomers(res.payload.map((customer) => ({ label: customer.name, value: customer.uuid })));
      });
  };

  const formInit = (data: IInboundAwb): void => {
    setForm({
      ...form,
      date: data.InboundManiFest.date.split('T')[0],
      flightNo: data.InboundManiFest.flightDetails.id.toString(),
      description: data.commDesc,
      totalNoOfPackages: data.InboundManiFest.totalPcs,
      grossWeight: data.InboundManiFest.grossWt,
      chargeableWeight: data.chWt,
    });
  };

  const onUpdateForm = (value: any, type: string): void => {
    const updatedForm: any = { ...form };
    const updatedErrorForm: any = { ...formError };
    updatedForm[type] = value;

    if (type === 'airWaybillNumber' && value.length !== 8) {
      updatedErrorForm['airWaybillNumber'] = true;
    } else {
      updatedErrorForm['airWaybillNumber'] = false;
    }

    setForm(updatedForm);
    setFormError(updatedErrorForm);
  };

  const setGstPctValue = (isAPState: boolean): void => {
    form.serviceProvidedList.forEach((service, serviceIdx) => {
      if (isAPState) {
        form.serviceProvidedList[serviceIdx].cgstPct = '9';
        form.serviceProvidedList[serviceIdx].cgstAmt =
          (parseFloat(form.serviceProvidedList[serviceIdx].amount) * (9 / 100)).toFixed(2);
        form.serviceProvidedList[serviceIdx].sgstPct = '9';
        form.serviceProvidedList[serviceIdx].sgstAmt =
          (parseFloat(form.serviceProvidedList[serviceIdx].amount) * (9 / 100)).toFixed(2);

        form.serviceProvidedList[serviceIdx].igstPct = '';
        form.serviceProvidedList[serviceIdx].igstAmt = '';
      } else {
        form.serviceProvidedList[serviceIdx].cgstPct = '';
        form.serviceProvidedList[serviceIdx].cgstAmt = '';
        form.serviceProvidedList[serviceIdx].sgstPct = '';
        form.serviceProvidedList[serviceIdx].sgstAmt = '';

        form.serviceProvidedList[serviceIdx].igstPct = '18';
        form.serviceProvidedList[serviceIdx].igstAmt =
          (parseFloat(form.serviceProvidedList[serviceIdx].amount) * (18 / 100)).toFixed(2);
      }
    });

    setForm({ ...form, serviceProvidedList: form.serviceProvidedList, });
  };

  const getTotalAmountTax = (itemIdx: number): number => {
    const { igstAmt, cgstAmt, sgstAmt } = form.serviceProvidedList[itemIdx];
    return (parseFloat(igstAmt) || 0) + (parseFloat(cgstAmt) || 0) + (parseFloat(sgstAmt) || 0);
  };

  const getNetAmount = (): number => {
    return form.serviceProvidedList.reduce((acc, value) => {
      acc += (parseFloat(value.amount) + parseFloat(value.totalTax));
      return acc;
    }, 0);
  };

  const addItem = (): void => {
    const items = [...form.serviceProvidedList];
    items.push(formInitial.serviceProvidedList[0]);
    setForm({
      ...form, serviceProvidedList: items.map(item => ({
        ...item,
        cgstPct: form.gstNo.substring(0, 2) === '37' ? '9' : '',
        sgstPct: form.gstNo.substring(0, 2) === '37' ? '9' : '',
        igstPct: form.gstNo.substring(0, 2) === '37' ? '' : '18',
      }))
    });

    const errItems = [...formError.serviceProvidedList];
    errItems.push(formErrorInit.serviceProvidedList[0]);
    setFormError({ ...formError, serviceProvidedList: errItems });
  };

  const removeItem = (itemIdx: number): void => {
    const items = [...form.serviceProvidedList];
    items.splice(itemIdx, 1);
    setForm({ ...form, serviceProvidedList: items });

    const errItems = [...formError.serviceProvidedList];
    errItems.splice(itemIdx, 1);
    setFormError({ ...formError, serviceProvidedList: errItems });
  };

  const formValidity = (): boolean => {
    return (
      form.awbNo !== '' &&
      form.description !== '' &&
      form.totalNoOfPackages !== '' &&
      form.grossWeight !== '' &&
      form.chargeableWeight !== '' &&
      form.agentCode !== '' &&
      form.agentName !== '' &&
      form.gstNo !== '' &&
      form.panNo !== '' &&
      form.consigneeName.length > 0 &&
      form.serviceProvidedList.reduce((acc, value) =>
        acc &&
        !!value.item &&
        parseFloat(value.rate) > 0 &&
        parseInt(value.quantity) > 0 &&
        parseFloat(value.amount) > 0, true)
    );
  };

  const onReset = (): void => {
    setForm(formInitial);
    setFormError(formErrorInit);
  };

  const onSave = (): void => {
    const payload: IPayload = {
      ...form,
      consigneeUuid: form.consigneeName[0].value,
      serviceProvidedList: form.serviceProvidedList.map(item => ({ ...item, item: item.item[0].value })),
    };

    (!!uuid ? updateInboundCustomerInvoiceAPI(uuid, payload) : createInboundCustomerInvoiceAPI(payload))
      .then((res: {payload: {inboundCustomerInvoiceUUID: string}}) => {
        if (!!res) {
          setUuid(res.payload.inboundCustomerInvoiceUUID);
          updateAlertText('Data saved successfully!!!', 'success');
        } else {
          updateAlertText('Something went wrong!!!', 'danger');
        }
      });
  };

  const isRateFieldDisabled = (selectedItem: ISelectDropdown[]): boolean =>
    itemListForServiceProvided.find(item =>
      item.name === (selectedItem.length > 0 ? selectedItem[0].value : ''))?.ratePerKg !== 0;

  return (
    <div className="content">
      <div className="card px-5 py-3">
        <div className="card-title mb-3">Shipment Info</div>

        <div className="row">
          <div className="col-2 mb-2">
            <label
              id="air-waybill-number"
              className="form-label-text d-block mb-1"
            >
              Air Waybill Number <span className="text-danger">*</span>
            </label>
            <input
              id="air-waybill-number"
              type="text"
              placeholder="Enter air waybill number"
              className="form-input-field w-100"
              disabled={!!uuid}
              onChange={(e) => onUpdateForm(e.target.value, 'awbNo')}
              value={form.awbNo}
            />
            {/*{
              formError.awbNo && form.awbNo.length !== 8 && (
                <p className="form-label-text text-danger">
                  Air waybill number should be 8 digits
                </p>
              )
            }*/}
          </div>

          {
            !uuid &&
            <div className="col-8 mt-4">
              <button
                className="app-btn app-btn-primary-inverted me-3"
                onClick={() => setForm(formInitial)}
              >
                Reset
              </button>
              <button
                className="app-btn app-btn-primary"
                onClick={() => getInboundManifestByAwbNo()}
              >
                Search
              </button>
            </div>
          }
        </div>

        <hr/>

        {
          form.flightNo !== '' && (
            <Fragment>
              <p className="card-title mb-3">Package Info</p>

              <div className="row">
                <div className="col-md-2">
                  <label
                    htmlFor="description"
                    className="form-label-text d-block mb-1"
                  >Description</label>
                  <input
                    id="description"
                    type="text"
                    placeholder="Enter agent code"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'description')}
                    value={form.description}
                  />
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="total-no-of-package"
                    className="form-label-text d-block mb-1"
                  >Total No Of Packages</label>
                  <input
                    id="total-no-of-package"
                    type="number"
                    placeholder="Enter total no of package"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'totalNoOfPackages')}
                    value={form.totalNoOfPackages}
                  />
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="gross-weight"
                    className="form-label-text d-block mb-1"
                  >Gross Weight</label>
                  <input
                    id="gross-weight"
                    type="number"
                    placeholder="Enter gross weight"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'grossWeight')}
                    value={form.grossWeight}
                  />
                </div>
                <div className="col-md-2">
                  <label
                    htmlFor="chargeable-weight"
                    className="form-label-text d-block mb-1">
                    Chargeable Weight</label>
                  <input
                    id="chargeable-weight"
                    type="number"
                    placeholder="Enter chargeable weight"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'chargeableWeight')}
                    value={form.chargeableWeight}
                  />
                </div>
              </div>

              <hr/>

              <p className="card-title mb-3">Agent Info</p>

              <div className="row">
                <div className="col-3 mb-3">
                  <label
                    htmlFor="agent-code"
                    className="form-label-text d-block mb-1"
                  >
                    Agent Code <span className="text-danger">*</span>
                  </label>
                  <input
                    id="agent-code"
                    type="text"
                    placeholder="Enter agent code"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'agentCode')}
                    value={form.agentCode}
                  />
                  {
                    formError.agentCode &&
                    <p className="form-label-text text-danger">Please enter agent code</p>
                  }
                </div>

                <div className="col-3 mb-3">
                  <label
                    htmlFor="agent-name"
                    className="form-label-text d-block mb-1"
                  >
                    Agent Name <span className="text-danger">*</span>
                  </label>
                  <input
                    id="agent-name"
                    type="text"
                    placeholder="Enter agent name"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'agentName')}
                    value={form.agentName}
                  />
                  {
                    formError.agentName &&
                    <p className="form-label-text text-danger">Please enter agent name</p>
                  }
                </div>

                <div className="col-3 mb-3">
                  <label
                    htmlFor="gst-no"
                    className="form-label-text d-block mb-1"
                  >
                    GST No <span className="text-danger">*</span>
                  </label>
                  <input
                    id="gst-no"
                    type="text"
                    placeholder="Enter gst no"
                    className="form-input-field w-100"
                    onBlur={(e) => {
                      const value = e.target.value;
                      setGstPctValue(value.substring(0, 2) === '37');
                    }}
                    onChange={(e) => onUpdateForm(e.target.value.toLocaleUpperCase(), 'gstNo')}
                    value={form.gstNo}
                  />
                  {
                    formError.gstNo &&
                    <p className="form-label-text text-danger">Please enter GST number</p>
                  }
                  {
                    !!form.gstNo && !validateGst(form.gstNo) &&
                    <p className="form-label-text text-danger">Please enter valid GST number</p>
                  }
                </div>

                <div className="col-3 mb-3">
                  <label
                    htmlFor="pan-no"
                    className="form-label-text d-block mb-1"
                  >
                    PAN No <span className="text-danger">*</span>
                  </label>
                  <input
                    id="pan-no"
                    type="text"
                    placeholder="Enter pan no"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value.toLocaleUpperCase(), 'panNo')}
                    value={form.panNo}
                  />
                  {
                    formError.panNo &&
                    <p className="form-label-text text-danger">Please enter PAN number</p>
                  }
                  {
                    !!form.panNo && !validatePan(form.panNo) &&
                    <p className="form-label-text text-danger">Please enter valid PAN number</p>
                  }
                </div>
              </div>

              <p className="card-title mb-3">Consignee Info</p>

              <div className="row">
                {/*<div className="col-3 mb-3">
                  <label
                    htmlFor="shipper-code"
                    className="form-label-text d-block mb-1"
                  >
                    Shipper Code <span className="text-danger">*</span>
                  </label>
                  <input
                    id="shipper-code"
                    type="text"
                    placeholder="Enter shipper code"
                    className="form-input-field w-100"
                    onChange={(e) => onUpdateForm(e.target.value, 'shipperCode')}
                    value={form.shipperCode}
                  />
                </div>*/}

                <div className="col-3 mb-3">
                  <label
                    htmlFor="consignee"
                    className="form-label-text d-block mb-1"
                  >
                    Consignee Name <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="consignee"
                    options={customers}
                    isSearchable
                    placeholder="Select Consignee Name"
                    onChange={(e: any) => onUpdateForm([e], 'consigneeName')}
                    value={form.consigneeName}
                  />
                </div>
              </div>

              <hr/>

              <p className="card-title mb-3">Services Provided</p>

              {
                form.serviceProvidedList.map((item, itemIdx: number) => (
                  <div key={itemIdx} className={`${styles['service-provided-list-container']} mb-3`}>
                    <div className="row">
                      <div className="col-11">
                        <div className="row">
                          <div className="col-3 mb-3">
                            <label
                              htmlFor={`item-${itemIdx}`}
                              className="form-label-text"
                            >
                              Item / Description <span className="text-danger">*</span>
                            </label>
                            <Select
                              id={`item-${itemIdx}`}
                              options={options}
                              isSearchable
                              placeholder="Select item"
                              onChange={(e: any) => {
                                form.serviceProvidedList[itemIdx].item = [e];
                                form.serviceProvidedList[itemIdx].rate =
                                  itemListForServiceProvided.find(obj =>
                                    obj.name === e.label)?.ratePerKg.toString() || '';
                                form.serviceProvidedList[itemIdx].quantity = '';
                                setForm({ ...form, serviceProvidedList: form.serviceProvidedList });
                              }}
                              value={form.serviceProvidedList[itemIdx].item}
                            />
                            {
                              formError.serviceProvidedList[itemIdx].item &&
                              <p className="form-label-text text-danger">Please select an item</p>
                            }
                          </div>

                          <div className="col-2 mb-3">
                            <label
                              htmlFor={`hsn-code-${itemIdx}`}
                              className="form-label-text"
                            >
                              HSN Code
                            </label>
                            <input
                              id={`hsn-code-${itemIdx}`}
                              type="number"
                              placeholder="Enter hsn code"
                              className="form-input-field w-100"
                              disabled={true}
                              onChange={() => null}
                              value={item.hsnCode}
                            />
                          </div>

                          <div className="col-2 mb-3">
                            <label
                              htmlFor={`rate-${itemIdx}`}
                              className="form-label-text"
                            >
                              Rate
                            </label>
                            <input
                              id={`rate-${itemIdx}`}
                              type="number"
                              placeholder="Enter rate"
                              className="form-input-field w-100"
                              disabled={isRateFieldDisabled(item.item)}
                              onChange={(e) => {
                                const value = e.target.value;
                                form.serviceProvidedList[itemIdx].rate = value;
                                setForm({ ...form, serviceProvidedList: form.serviceProvidedList });
                              }}
                              value={item.rate}
                            />
                            {
                              formError.serviceProvidedList[itemIdx].rate &&
                              <p className="form-label-text text-danger">
                                Please enter rate, shouldn't less than or equal to 0
                              </p>
                            }
                          </div>

                          <div className="col-2 mb-3">
                            <label
                              htmlFor={`quantity-${itemIdx}`}
                              className="form-label-text"
                            >
                              Quantity <span className="text-danger">*</span>
                            </label>
                            <input
                              id={`quantity-${itemIdx}`}
                              type="number"
                              placeholder="Enter quantity"
                              className="form-input-field w-100"
                              onChange={(e) => {
                                const value = e.target.value;
                                form.serviceProvidedList[itemIdx].quantity = value;

                                const minPerAwb = item.item.length
                                  ? (itemListForServiceProvided.find(obj =>
                                    obj.name === item.item[0].value)?.minPerAwb || 0)
                                  : 0;
                                const total = parseFloat(item.rate) * parseInt(item.quantity);
                                const amount = total > minPerAwb ? total : minPerAwb;
                                form.serviceProvidedList[itemIdx].amount = amount.toFixed(2);
                                formError.serviceProvidedList[itemIdx].amount =
                                  (minPerAwb || 0) >= (parseFloat(item.rate) * parseInt(item.quantity));

                                form.serviceProvidedList[itemIdx].igstAmt =
                                  (amount * (parseFloat(form.serviceProvidedList[itemIdx].igstPct) / 100)).toFixed(2);
                                form.serviceProvidedList[itemIdx].cgstAmt =
                                  (amount * (parseFloat(form.serviceProvidedList[itemIdx].cgstPct) / 100)).toFixed(2);
                                form.serviceProvidedList[itemIdx].sgstAmt =
                                  (amount * (parseFloat(form.serviceProvidedList[itemIdx].sgstPct) / 100)).toFixed(2);
                                form.serviceProvidedList[itemIdx].totalTax =
                                  getTotalAmountTax(itemIdx).toFixed(2);
                                form.netAmount = Math.round(getNetAmount()).toFixed(2);

                                setForm({ ...form, serviceProvidedList: form.serviceProvidedList });
                                setFormError({ ...formError, serviceProvidedList: formError.serviceProvidedList });
                              }}
                              value={item.quantity}
                            />
                            {
                              formError.serviceProvidedList[itemIdx].quantity &&
                              <p className="form-label-text text-danger">
                                Please enter quantity, shouldn't less than or equal to 0
                              </p>
                            }
                          </div>

                          <div className="col-2 mb-3">
                            <label
                              htmlFor={`amount-${itemIdx}`}
                              className="form-label-text"
                            >
                              Amount
                            </label>
                            <input
                              id={`amount-${itemIdx}`}
                              type="number"
                              placeholder="Enter amount"
                              className="form-input-field w-100"
                              disabled={true}
                              onChange={() => null}
                              value={form.serviceProvidedList[itemIdx].amount}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-1 content-center">
                        {(form.serviceProvidedList.length === 1 ||
                          form.serviceProvidedList.length - 1 === itemIdx) && (
                          <i
                            className="fa fa-plus text-primary cursor-pointer"
                            onClick={() => addItem()}
                          />
                        )}
                        {form.serviceProvidedList.length !== 1 && (
                          <i
                            className="fa fa-trash text-danger cursor-pointer ms-3"
                            onClick={() => removeItem(itemIdx)}
                          />
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3 mb-3">
                        <label className="form-label-text d-block mb-1">IGST</label>

                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="igst-rate" className="form-label-text d-block mb-1">Rate %</label>
                            <input
                              id="igst-rate"
                              type="number"
                              placeholder="Enter rate"
                              className="form-input-field w-100"
                              disabled={true}
                              onChange={() => null}
                              value={item.igstPct}
                            />
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="igst-amount" className="form-label-text d-block mb-1">Amount</label>
                            <input
                              id="igst-amount"
                              type="number"
                              placeholder="Enter amount"
                              className="form-input-field w-100"
                              disabled={true}
                              onChange={() => null}
                              value={item.igstAmt}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-3 mb-3">
                        <label className="form-label-text d-block mb-1">CGST</label>

                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="cgst-rate" className="form-label-text d-block mb-1">Rate %</label>
                            <input
                              id="cgst-rate"
                              type="number"
                              placeholder="Enter rate"
                              className="form-input-field w-100"
                              disabled={true}
                              onChange={() => null}
                              value={item.cgstPct}
                            />
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="cgst-amount" className="form-label-text d-block mb-1">Amount</label>
                            <input
                              id="cgst-amount"
                              type="number"
                              placeholder="Enter amount"
                              className="form-input-field w-100"
                              disabled={true}
                              onChange={() => null}
                              value={item.cgstAmt}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-3 mb-3">
                        <label className="form-label-text d-block mb-1">SGST</label>

                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="sgst-rate" className="form-label-text d-block mb-1">Rate %</label>
                            <input
                              id="sgst-rate"
                              type="number"
                              placeholder="Enter rate"
                              className="form-input-field w-100"
                              disabled={true}
                              onChange={() => null}
                              value={item.sgstPct}
                            />
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="sgst-amount" className="form-label-text d-block mb-1">Amount</label>
                            <input
                              id="sgst-amount"
                              type="number"
                              placeholder="Enter amount"
                              className="form-input-field w-100"
                              disabled={true}
                              onChange={() => null}
                              value={item.sgstAmt}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-3 d-inline-grid align-items-end mb-3">
                        <label
                          htmlFor="total-tax"
                          className="form-label-text d-block mb-1"
                        >
                          Total Tax
                        </label>
                        <input
                          id="total-tax"
                          type="number"
                          placeholder="Enter total tax"
                          className="form-input-field w-100"
                          disabled={true}
                          onChange={() => null}
                          // value={getTotalAmountTax(itemIdx)}
                          value={item.totalTax}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }

              <hr/>

              <div className="row">
                <div className="offset-10 col-2 mb-3">
                  <label
                    htmlFor="net-amount"
                    className="form-label-text d-block mb-1"
                  >
                    Net Amount
                  </label>
                  <input
                    id="net-amount"
                    type="number"
                    placeholder="Enter net amount"
                    className="form-input-field w-100"
                    disabled={true}
                    onChange={() => null}
                    value={form.netAmount}
                  />
                </div>
              </div>

              <div className="text-right mt-4">
                <button
                  className="app-btn app-btn-primary me-2"
                  disabled={!formValidity()}
                  onClick={() => onSave()}
                >
                  Save
                </button>
                <button
                  className="app-btn app-btn-primary"
                  disabled={!uuid}
                  onClick={() => navigate(`/inbound-tax-invoice/${uuid}`)}
                >
                  Invoice
                </button>
              </div>
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

export default EditInboundCustomerInvoicePage;
