import { IPayload } from '../interfaces/inboundCustomerInvoice.interface';
import { getRequest, HandlerResponse, postRequest, putRequest } from './api';

export const createInboundCustomerInvoiceAPI = async (payload: IPayload): Promise<any> => {
  return postRequest(`${process.env.REACT_APP_API_ENDPOINT}/inbound-customer/create-invoice`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const getInboundCustomerInvoiceAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/inbound-customer/all-invoice`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getInboundCustomerInvoiceByIdAPI = async (id: string): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/inbound-customer/invoice-by-id/${id}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const updateInboundCustomerInvoiceAPI = async (id: string, payload: IPayload): Promise<any> => {
  return putRequest(
    `${process.env.REACT_APP_API_ENDPOINT}/inbound-customer/update-inbound-customer-invoice/${id}`,
    payload
  ).then((resp: any) => HandlerResponse(resp));
};
