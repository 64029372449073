import React, { ReactElement, useRef, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import AddCustomerScreen from '../customer/AddCustomerScreen';

interface IProps {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  onSave: () => void,
}

const AddCustomerModal = (props: IProps): ReactElement => {
  const { isOpen, setIsOpen, onSave, } = props;

  const childRef = useRef<any>(null);

  const [isSaveValid, setIsSaveValid] = useState<boolean>(false);

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      style={{ maxWidth: '70%' }}
    >
      <ModalHeader>
        <p className="modal-title">Add Customer</p>
      </ModalHeader>

      <ModalBody>
        <AddCustomerScreen
          ref={childRef}
          isModal={true}
          onCheckFormValidity={(status: boolean) => setIsSaveValid(status)}
          onAdd={() => onSave()}
        />
      </ModalBody>

      <ModalFooter>
        <p
          className="form-label-text text-danger cursor-pointer me-4"
          onClick={() => setIsOpen(false)}
        >Cancel</p>
        <button
          className="app-btn app-btn-primary"
          disabled={!isSaveValid}
          onClick={() => {
            // setIsOpen(false);
            childRef.current.onCreate();
          }}
        >Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddCustomerModal;
