import { IOutboundCustomerInvoicePayload } from '../interfaces/outboundCustomerInvoice.interface';
import { getRequest, HandlerResponse, postRequest, putRequest } from './api';

export const createOutboundCustomerInvoiceAPI = async (payload: IOutboundCustomerInvoicePayload): Promise<any> => {
  return postRequest(`${process.env.REACT_APP_API_ENDPOINT}/outbound-customer/create-invoice`, payload)
    .then((resp: any) => HandlerResponse(resp));
};

export const getOutboundCustomerInvoiceAPI = async (): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/outbound-customer/all-invoice`)
    .then((resp: any) => HandlerResponse(resp));
};

export const getOutboundCustomerInvoiceByIdAPI = async (id: string): Promise<any> => {
  return getRequest(`${process.env.REACT_APP_API_ENDPOINT}/outbound-customer/invoice-by-id/${id}`)
    .then((resp: any) => HandlerResponse(resp));
};

export const updateOutboundCustomerInvoiceAPI = async (
  id: string,
  payload: IOutboundCustomerInvoicePayload
): Promise<any> => {
  return putRequest(
    `${process.env.REACT_APP_API_ENDPOINT}/outbound-customer/update-outbound-customer-invoice/${id}`,
    payload
  ).then((resp: any) => HandlerResponse(resp));
};
